import styled from 'styled-components';

import { device, size } from '../../constants/screenSizes';
import Legend from '../../components/texts/CustomLegend';

export const Main = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;

  @media ${device.laptop} {
    max-width: ${() => size.laptop}px;
  }
`;

export const ItalicInfo = styled.p`
  font-style: italic;
  margin: 0;
`;

export const AccountContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  padding: 24px 0 40px 0;
  width: 100%;
`;

export const AccountItem = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.classicButton};
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: fit-content;
  justify-content: flex-start;
  padding: 24px;
  width: 100%;

  @media ${device.laptop} {
    width: fit-content;
  }
`;

export const InfoWithIcon = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Mail = styled(Legend)`
  margin: 0;
  text-align: left;
  word-break: break-all;
`;

export const Info = styled.p<{ header?: boolean }>`
  font-style: ${(props) => (props.header ? 'italic' : 'none')};
  margin: 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
`;
