import format from 'date-fns/format';
import { enUS, fr } from 'date-fns/locale';

import i18n, { Language } from '../../i18n';

type LitteralFormat =
  | 'dd/MM/yyyy - HH:mm'
  | 'dd/MM/yyyy HH:mm'
  | 'dd/MM/yyyy'
  | 'MMMM yyyy'
  | 'yyyy';

type AlternativeLitteralFormat =
  | 'MM/dd/yyyy - hh:mm aaa'
  | 'MM/dd/yyyy hh:mm aaa'
  | 'MM/dd/yyyy';

export const getTimestampFromStrDate = (date?: string) =>
  Math.trunc(new Date(date ?? Date.now()).getTime() / 1000);

export const getYearFromTimestamp = (timestamp: number) =>
  new Date(timestamp * 1000).getFullYear();

const adaptWithLocale = (
  litteralFormat: LitteralFormat,
): LitteralFormat | AlternativeLitteralFormat => {
  if (i18n.language === Language.FR) return litteralFormat;

  switch (litteralFormat) {
    case 'dd/MM/yyyy - HH:mm':
      return 'MM/dd/yyyy - hh:mm aaa';
    case 'dd/MM/yyyy':
      return 'MM/dd/yyyy';
    default:
      return litteralFormat;
  }
};

export const formatDate = (
  date: Date | number,
  litteralFormat: LitteralFormat,
) => {
  return format(
    new Date(typeof date === 'number' ? date * 1000 : date),
    adaptWithLocale(litteralFormat),
    {
      locale: i18n.language === Language.FR ? fr : enUS,
    },
  );
};
