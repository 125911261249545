import styled from 'styled-components';

export const Main = styled.div<{ disabled?: boolean }>`
  align-items: center;
  background-color: transparent;
  margin-bottom: 24px; //TODO remove and put gap in all parents
  max-width: 400px;
  width: 90% !important;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

export const Container = styled.div<{ isTextArea?: boolean }>`
  align-items: center;
  background-color: transparent;
  border: ${(props) => `2px solid ${props.color}`};
  border-radius: ${(props) => (props.isTextArea ? '16px' : '32px')};
  display: flex;
  min-height: 40px;
  justify-content: space-around;
  max-width: 400px;
  padding: 2px 16px;
`;

export const InputCore = styled.input`
  all: unset;
  color: ${(props) => props.color};
  margin: 0;
  height: 100%;
  text-align: left;
  padding: 0 16px;
  width: 100%;
  text-transform: none;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${(props) => props.color} !important;
    caret-color: ${(props) => props.color} !important;
  }
`;
export const TextAreaCore = styled.textarea`
  all: unset;
  color: ${(props) => props.color};
  min-height: 40px;
  resize: none;
  text-align: left;
  padding: 16px;
  width: 100%;
  text-transform: none;
  white-space: pre-line;
  word-wrap: break-word;
`;

export const InputErrorMassage = styled.p`
  color: ${(props) => props.color};
  margin: 0;
  padding-top: 8px;
  text-align: left;
  text-transform: none;
`;

export const InputHeaderText = styled.p`
  color: ${(props) => props.color};
  margin: 0;
`;

export const InputHeader = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;
