import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';
import Modal from 'react-modal';

import { Theme } from '../../../theme';
import CustomTitle from '../texts/CustomTitle';
import TextIconButton from '../textIconButton/TextIconButton';

import { Main, Footer, Body } from './style';

type Button = {
  color: string;
  iconName: string;
  text: string;
  action: () => void;
};

type Props = {
  button: Button;
  children: React.ReactNode;
  closeAction: () => void;
  ismodalOpen: boolean;
  loading?: boolean;
  secondaryButton?: Button;
  style?: React.CSSProperties;
  title: string;
  theme: Theme;
};

const CUSTOM_STYLE = {
  content: {
    alignItems: 'center',
    borderRadius: 16,
    bottom: 'auto',
    display: 'flex',
    flexDirection: 'column' as any,
    left: '50%',
    marginRight: '-50%',
    maxHeight: '86%',
    maxWidth: '80%',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

const EditionModal = (props: Props): ReactElement => {
  const {
    button,
    children,
    closeAction,
    ismodalOpen,
    loading,
    secondaryButton,
    style,
    title,
    theme,
  } = props;

  return (
    <Main id="EditionModal" style={style}>
      <Modal
        contentLabel="Confirmation Modal"
        ariaHideApp={false}
        isOpen={ismodalOpen}
        onRequestClose={closeAction}
        style={{
          content: {
            ...CUSTOM_STYLE.content,
            backgroundColor: theme.background,
            zIndex: 1200,
          },
          overlay: {
            backgroundColor: `${theme.background}E6`,
            zIndex: 1200,
          },
        }}
      >
        <CustomTitle color={theme.text} style={{ paddingBottom: 32 }}>
          {title}
        </CustomTitle>
        <Body>{children}</Body>
        <Footer>
          {secondaryButton ? (
            <TextIconButton
              color={secondaryButton.color}
              iconName={secondaryButton.iconName}
              onClick={() => secondaryButton.action()}
              title={secondaryButton.text}
            />
          ) : null}
          <TextIconButton
            disabled={loading}
            color={button.color}
            iconName={button.iconName}
            loading={loading}
            onClick={() => button.action()}
            title={button.text}
          />
        </Footer>
      </Modal>
    </Main>
  );
};

export default withTheme(EditionModal);
