import styled from 'styled-components';

import { device, size } from '../../constants/screenSizes';

export const Main = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;

  @media ${device.laptop} {
    max-width: ${() => size.laptop}px;
  }
`;
export const MainArticle = styled(Main)`
  padding: 48px 0;
`;

export const MapSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px;
  width: 90%;
`;

export const YoutubePlaceHolder = styled.div`
  height: 390px;
  width: 100%;
  background-color: ${(props) => props.theme.classicButton};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;
