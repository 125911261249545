import styled from 'styled-components';

export const Main = styled.div<{ isEasterEgg: boolean }>`
  display: flex !important;
  flex-direction: column !important;
  max-width: 100% !important;
  min-height: calc(100vh - 5rem) !important;
  position: relative !important;
  width: 100vw !important;
  padding-bottom: ${(props) =>
    props.isEasterEgg ? '400px' : 0} !important;
`;
