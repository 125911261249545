import React, { ReactElement, useState } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import CustomPageTitle from '../../../components/texts/CustomPageTitle';
import { useHistory, useLocation } from 'react-router-dom';
import TextIconButton from '../../../components/textIconButton/TextIconButton';
import { icons } from '../../../constants/icons';
import { getUserInfo } from '../../../firebase/user';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../firebase/connection';
import {
  createBlogArticle,
  updateBlogArticle,
} from '../../../firebase/blog';
import { getTimestampFromStrDate } from '../../../utils/date';
import { goTo, routes } from '../../../router/routes';
import Input from '../../../components/input/Input';
import { BlogArticle } from '../../../types/blog';
import Editor from '../../../components/editor/Editor';

import { Theme } from '../../../../theme';

import { Main } from './style';
import { articleEditorToolsConfig } from '../../../components/editor/ArticleEditorToolsConfig';

type Props = { theme: Theme };

const BlogEdition = (props: Props): ReactElement => {
  const { theme } = props;
  const history = useHistory();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const [user] = useAuthState(auth);

  const params: BlogArticle = location.state as BlogArticle;
  const [title, setTitle] = useState<string>(
    params ? params.title : '',
  );
  const [article, setArticle] = useState(
    params ? JSON.parse(params.message) : undefined,
  );

  const isInModification = !!params;

  const saveArticle = async (isDraft: boolean) => {
    const u = await getUserInfo(user?.uid);

    isInModification
      ? await updateBlogArticle(
          params.uid,
          JSON.stringify(article),
          title,
          isDraft,
        )
      : await createBlogArticle(
          {
            author: u.uid,
            simplifiedAuthor: `${u.firstName} ${u.lastName}`,
            createdDate: getTimestampFromStrDate(),
            message: JSON.stringify(article),
            title: title,
          },
          isDraft,
        );
    goTo(location, history, routes.BLOG.MAIN, false);
  };

  return (
    <Main>
      <CustomPageTitle
        color={theme.text}
        style={{ paddingBottom: 32, textAlign: 'center' }}
      >
        {'Ecrire un article'}
      </CustomPageTitle>
      <Input
        autoFocus
        color={props.theme.text}
        leftIconName={icons.TEXT_OUTLINE}
        onChange={(e: any) => setTitle(e.target.value)}
        placeholder={'Title'}
        title={'Article title'}
        type="text"
        value={title}
        required={true}
        name="title"
      />
      <Editor
        data={article}
        holder={'newArticleWithoutUID'}
        setArticle={setArticle}
        tools={articleEditorToolsConfig}
      />
      <div style={{ display: 'flex', gap: 16, marginTop: 40 }}>
        <TextIconButton
          color={theme.danger}
          iconName={icons.CLOSE_OUTLINE}
          onClick={() => {
            goTo(location, history, routes.BLOG.MAIN, false);
          }}
          title={'Quitter'}
        />
        <TextIconButton
          color={theme.text}
          iconName={icons.SAVE_OUTLINE}
          onClick={() => saveArticle(true)}
          title={'Mettre en brouillon'}
        />
        <TextIconButton
          color={theme.text}
          iconName={icons.CLOUD_UPLOAD_OUTLINE}
          onClick={() => saveArticle(false)}
          title={isInModification ? 'Mettre à jour' : 'Publier'}
        />
      </div>
    </Main>
  );
};

export default withTheme(BlogEdition);
