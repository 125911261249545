export const stringToHslColor = (name: string) => {
  let hash = 0;
  const nameWithoutSpace = name.toLowerCase().replace(' ', '');

  for (let i = 0; i < nameWithoutSpace.length; i++) {
    hash = nameWithoutSpace.charCodeAt(i) + ((hash << 5) - hash);
  }

  return `hsl(${Math.abs(hash % 360)} 50% 60%)`;
};

export const getFullNameInitials = (name: string) => {
  if (name.includes(' ')) {
    const words = name.trim().split(' ');
    const firstInitial = words[0][0];
    const lastInitial = words[words.length - 1][0];
    return `${firstInitial}.${lastInitial}`.toUpperCase();
  } else return name[0];
};
