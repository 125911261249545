import React, {
  ReactElement,
  useLayoutEffect,
  useState,
  useEffect,
} from 'react';
import { withTheme } from 'styled-components';
import { Theme } from '../../../theme';

import Icon from '../icon/Icon';
import IconButton from '../iconButton/IconButton';
import Loader from '../loader/Loader';

import {
  Container,
  InputCore,
  InputErrorMassage,
  InputHeader,
  Main,
  InputHeaderText,
  TextAreaCore,
} from './style';

type Props = {
  color: string;
  defaultValue?: string;
  disabled?: boolean;
  error?: string;
  iconColor?: string;
  iconStyle?: React.CSSProperties;
  isTextArea?: boolean;
  leftIconName?: string;
  charLimit?: number;
  loading?: boolean;
  name: string;
  onChange: (e: any) => void;
  onRightIconClick?: () => void;
  placeholder: string;
  required?: boolean;
  rightIconName?: string;
  style?: React.CSSProperties;
  title: string;
  theme: Theme;
  type: 'email' | 'password' | 'tel' | 'text';
  value: string;
};

const Input = (
  props: Props &
    React.InputHTMLAttributes<HTMLInputElement> &
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
): ReactElement => {
  const {
    color,
    defaultValue,
    disabled,
    error,
    iconColor,
    iconStyle,
    isTextArea,
    leftIconName,
    charLimit,
    loading,
    name,
    onChange,
    onRightIconClick,
    placeholder,
    required,
    rightIconName,
    style,
    title,
    theme,
    type,
    value,
    ...propsRest
  } = props;
  const [charCount, setCharCount] = useState(
    charLimit ? value.length : undefined,
  );
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  useLayoutEffect(() => {
    if (isTextArea) {
      textareaRef.current!.style.height = 'inherit';
      textareaRef.current!.style.height = `${Math.max(
        textareaRef.current!.scrollHeight - 24,
        38,
      )}px`;
    }
  }, [value]);

  useEffect(() => {
    setCharCount(value.length);
  }, [value]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (charLimit && e.target.value.length > charLimit) {
      return;
    }
    if (onChange) {
      onChange(e);
    }
    setCharCount(e.target.value.length);
  };

  return (
    <Main disabled={disabled} id="Input" style={style}>
      <InputHeader>
        <InputHeaderText color={color} style={{ paddingBottom: 8 }}>
          {`${title} ${required ? '*' : ''}`}
        </InputHeaderText>
        {charLimit ? (
          <InputHeaderText color={color} style={{ paddingBottom: 8 }}>
            {`${charCount}/${charLimit}`}
          </InputHeaderText>
        ) : null}
      </InputHeader>
      <Container color={color} isTextArea={isTextArea}>
        {leftIconName && !loading && (
          <Icon
            name={leftIconName}
            size={'24px'}
            color={iconColor ?? color}
            style={iconStyle}
          />
        )}
        {loading && <Loader size={'24px'} style={{ width: 24 }} />}
        {isTextArea ? (
          <TextAreaCore
            color={color}
            disabled={disabled}
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
            ref={textareaRef}
            required={required}
            rows={1}
            value={value}
            {...propsRest}
          />
        ) : (
          <InputCore
            color={color}
            disabled={disabled}
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
            type={type}
            required={required}
            value={value}
            {...propsRest}
          />
        )}
        {rightIconName && (
          <IconButton
            color={iconColor ?? color}
            iconName={rightIconName}
            onClick={() =>
              onRightIconClick ? onRightIconClick() : null
            }
            style={{ padding: 0, ...iconStyle }}
          />
        )}
      </Container>
      {error && (
        <InputErrorMassage color={theme.danger}>
          {error}
        </InputErrorMassage>
      )}
    </Main>
  );
};

export default withTheme(Input);
