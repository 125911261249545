import styled from 'styled-components';
import Paragraph from '../../components/paragraph/Paragraph';
import { device } from '../../constants/screenSizes';

export const GridsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 50%;
  padding-top: 40px;

  @media ${device.tablet} {
    max-width: 90%;
  }
  @media ${device.laptop} {
    max-width: 50%;
  }
`;

export const Grid = styled.div`
  align-items: flex-start;
  display: grid;
  grid-template-columns: 8px 72px 136px 136px auto;
  padding: 0px 8px 0px 0px;
  width: 100%;
`;

export const Line = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 48px;
  height: 100%;
  width: 8px;
`;

export const Legends = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Legend = styled(Paragraph).attrs((props) => ({
  style: {
    border: `2px solid ${props.color}`,
    borderRadius: 48,
    margin: '0 8px 0 8px',
    padding: '0 8px 0 8px',
  },
}))``;
