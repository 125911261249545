import React, { ReactElement, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../../theme';
import Loader from '../../../components/loader/Loader';
import TextIconButton from '../../../components/textIconButton/TextIconButton';
import CustomPageTitle from '../../../components/texts/CustomPageTitle';
import CustomSubTitle from '../../../components/texts/CustomSubTitle';
import CustomTitle from '../../../components/texts/CustomTitle';
import { icons } from '../../../constants/icons';
import { auth } from '../../../firebase/connection';
import {
  adminReActivateEvent,
  listenToNextMonthEvents,
  suscribMemberOnSession,
} from '../../../firebase/events';
import { Event, Events } from '../../../types/event';
import { formatDate } from '../../../utils/date';
import Tag from '../../../components/tag/Tag';
import Alert from '../../../components/alert/Alert';
import { capitalizeFirstLetter } from '../../../utils/textDecoration';
import AssignUserToEventModal from './AssignUserToEventModal';
import { Role } from '../../../types/users';
import { getUserRole } from '../../../firebase/user';
import { ToastLevels, displayToast } from '../../../utils/Toast';
import { TOAST_LEVELS } from '../../../constants/toastLevels';
import AdminCancelEventModal from './AdminCancelEventModal';
import { ROLE } from '../../../constants/role';
import { handleFirebaseErrors } from '../../../utils/errors';

import {
  Header,
  HeaderButtons,
  Main,
  OpenerContainer,
  SessionContainer,
  SessionText,
  Status,
} from './style';

type Props = { theme: Theme };

const Planning = (props: Props): ReactElement => {
  const { theme } = props;

  const { t } = useTranslation();

  const [events, setEvents] = useState([] as Events);
  const [userRole, setUserRole] = useState('' as Role);
  const [loading, setLoading] = useState(true);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [
    isAssignUserToEventModalOpen,
    setIsAssignUserToEventModalOpen,
  ] = useState(false);
  const [
    isAdminCancelEventModalOpen,
    setIsAdminCancelEventModalOpen,
  ] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<Event | null>(
    null,
  );

  const [user] = useAuthState(auth);

  useEffect(() => {
    let unsubscribeUsers: any;

    const fetchData = async () => {
      setUserRole(await getUserRole(user!.uid));
    };

    fetchData();

    try {
      unsubscribeUsers = listenToNextMonthEvents((ev) => {
        setEvents(ev);
      });
    } catch (e) {
      // TODO handleError func
    } finally {
      setLoading(false);
    }

    return () => {
      if (unsubscribeUsers) unsubscribeUsers();
    };
  }, []);

  useEffect(() => {
    if (currentEvent) {
      const updatedEvent = events.find(
        (event) => event.uid === currentEvent.uid,
      );
      if (
        !updatedEvent ||
        updatedEvent.status !== currentEvent.status ||
        updatedEvent.assignedMember !== currentEvent.assignedMember
      ) {
        onConfirmModalClose();
        onAssignUserToEventModalClose();
      }
    }
  }, [events]);

  const suscribeUserToEvent = async (id: string) => {
    try {
      await suscribMemberOnSession(id, user?.uid!);
      document.body.style.overflow = 'inherit';
      displayToast(
        TOAST_LEVELS.SUCCESS as ToastLevels,
        t('PLANNING.SUSCRIBE_OK_TOAST'),
      );
    } catch (error: any) {
      handleFirebaseErrors(error.code || error.message);
    } finally {
      onConfirmModalClose();
    }
  };

  const onConfirmModalOpen = (event: Event) => {
    setCurrentEvent(event);
    setIsConfirmModalOpen(true);
  };

  const onConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
    setCurrentEvent(null);
  };

  const onAssignUserToEventModalOpen = (event: Event) => {
    setCurrentEvent(event);
    setIsAssignUserToEventModalOpen(true);
  };

  const onAssignUserToEventModalClose = () => {
    setCurrentEvent(null);
    setIsAssignUserToEventModalOpen(false);
  };

  const onAdminCancelEventModalOpen = (event: Event) => {
    setCurrentEvent(event);
    setIsAdminCancelEventModalOpen(true);
  };

  const onAdminCancelEventModalClose = () => {
    setCurrentEvent(null);
    setIsAdminCancelEventModalOpen(false);
  };

  const isPassedEvent = (date: number) =>
    new Date(date * 1000 + 86400000) < new Date();

  const statusToDisplay = (
    status: string,
    date: number,
  ): { color: string; text: string } => {
    if (isPassedEvent(date)) {
      return {
        color: theme.off,
        text: t('COMMON.EVENT_STATUS.FINISHED'),
      };
    }

    switch (status) {
      case 'validated':
        return {
          color: theme.success,
          text: t('COMMON.EVENT_STATUS.VALIDATED'),
        };
      case 'canceled':
        return {
          color: theme.danger,
          text: t('COMMON.EVENT_STATUS.CANCELED'),
        };
      case 'pending':
        return {
          color: theme.warning,
          text: t('COMMON.EVENT_STATUS.PENDING'),
        };
      default:
        return { color: theme.text, text: '?' }; //TODO change '?' by text
    }
  };

  function renderAssignButton(event: Event) {
    const isUserAssigned = user?.uid !== event.assignedMember;
    const isEventCanceled = event.status === 'canceled';
    const isEventPassed = isPassedEvent(event.date);

    return event.assignedMember ? (
      <TextIconButton
        disabled={isUserAssigned || isEventCanceled || isEventPassed}
        color={theme.text}
        iconName={icons.CREATE_OUTLINE}
        onClick={() => onAssignUserToEventModalOpen(event)}
        title={t('COMMON.MODIFY')}
      />
    ) : (
      <TextIconButton
        color={theme.text}
        disabled={isEventPassed || isEventCanceled}
        iconName={icons.PERSON_ADD_OUTLINE}
        onClick={() => onConfirmModalOpen(event)}
        title={t('PLANNING.ASSIGN')}
      />
    );
  }

  const reActivateEvent = async (event: Event) => {
    try {
      await adminReActivateEvent(event.uid, !!event.assignedMember);
      displayToast(
        TOAST_LEVELS.SUCCESS as ToastLevels,
        t('PLANNING.REACTIVATED_OK_TOAST'),
      );
    } catch (error: any) {
      handleFirebaseErrors(error.code || error.message);
    }
  };

  const renderOfficeAdminEventManagingButton = (event: Event) => {
    const isMember = userRole === ROLE.MEMBER;
    const isEventCanceled = event.status === 'canceled';

    if (isMember) {
      return null;
    }
    if (isEventCanceled) {
      return (
        <TextIconButton
          color={theme.success}
          iconName={icons.CHECKMARK_OUTLINE}
          onClick={() => reActivateEvent(event)}
          title={t('PLANNING.REOPEN_EVENT')}
          disabled={isPassedEvent(event.date)}
        />
      );
    }
    return (
      <TextIconButton
        color={theme.danger}
        iconName={icons.TRASH_OUTLINE}
        onClick={() => onAdminCancelEventModalOpen(event)}
        title={t('PLANNING.CANCEL_EVENT')}
        disabled={isPassedEvent(event.date)}
      />
    );
  };

  if (loading) return <Loader size="48px" />;

  return (
    <Main>
      <CustomPageTitle
        color={theme.text}
        style={{ paddingBottom: 32, textAlign: 'center' }}
      >
        {t('PLANNING.TITLE')}
      </CustomPageTitle>
      {events.map((event) => {
        return (
          <SessionContainer key={event.uid}>
            <Header id="header">
              <CustomTitle
                color={theme.text}
                style={{
                  flex: 1,
                  textAlign: 'center',
                  paddingLeft: 8,
                  paddingRight: 8,
                  marginBottom: 8,
                  textDecoration:
                    event.status === 'canceled'
                      ? 'line-through'
                      : 'none',
                }}
              >
                {t('PLANNING.SESSION_DATE', {
                  date: formatDate(event.date, 'dd/MM/yyyy'),
                })}
              </CustomTitle>
              <HeaderButtons>
                {renderAssignButton(event)}
                {renderOfficeAdminEventManagingButton(event)}
              </HeaderButtons>
            </Header>
            {event.status === 'canceled' && event.cancelInfo ? (
              <CustomTitle
                color={theme.text}
                style={{ textAlign: 'left' }}
              >
                {`⚠️ ${t('PLANNING.CANCEL_REASON', {
                  info: capitalizeFirstLetter(event.cancelInfo),
                })} ⚠️`}
              </CustomTitle>
            ) : null}
            <OpenerContainer>
              <SessionText isCanceled={event.status === 'canceled'}>
                {t('PLANNING.OPENER')}
              </SessionText>
              {event.assignedMember && event.status !== 'canceled' ? (
                <Tag
                  color={theme.text}
                  text={event.simplifiedAssignedMember}
                  userUid={event.assignedMember}
                />
              ) : null}
            </OpenerContainer>
            <SessionText isCanceled={event.status === 'canceled'}>
              {t('PLANNING.THEME_INFO', {
                theme: capitalizeFirstLetter(event.theme),
              })}
            </SessionText>
            <SessionText isCanceled={event.status === 'canceled'}>
              {t('PLANNING.NOTE_INFO', {
                note: capitalizeFirstLetter(event.note),
              })}
            </SessionText>
            <Status
              color={statusToDisplay(event.status, event.date).color}
            >
              <CustomSubTitle
                color={theme.text}
                style={{
                  padding: '8px 24px',
                  backgroundColor: theme.classicButton,
                  borderRadius: 4,
                }}
              >
                {statusToDisplay(event.status, event.date).text}
              </CustomSubTitle>
            </Status>
          </SessionContainer>
        );
      })}
      {currentEvent ? (
        <Alert
          button={{
            color: theme.success,
            iconName: icons.CHECKMARK_OUTLINE,
            text: t('COMMON.CONFIRM'),
            action: () => suscribeUserToEvent(currentEvent.uid),
          }}
          closeAction={() => onConfirmModalClose()}
          ismodalOpen={isConfirmModalOpen}
          message={t('PLANNING.CONFIRM_ALERT_DESCRIPTION', {
            date: formatDate(currentEvent.date, 'dd/MM/yyyy'),
          })}
          secondaryButton={{
            color: theme.danger,
            iconName: icons.CLOSE_OUTLINE,
            text: t('COMMON.CANCEL'),
            action: () => onConfirmModalClose(),
          }}
          title={t('PLANNING.CONFIRM_ALERT_TITLE')}
        />
      ) : null}
      {currentEvent && user ? (
        <AssignUserToEventModal
          isAssignUserToEventModalOpen={isAssignUserToEventModalOpen}
          closeAssignUserToEventModal={onAssignUserToEventModalClose}
          currentEvent={currentEvent}
          userRole={userRole}
          userUid={user.uid}
        />
      ) : null}
      {currentEvent && user ? (
        <AdminCancelEventModal
          isAssignUserToEventModalOpen={isAdminCancelEventModalOpen}
          currentEvent={currentEvent}
          closeAdminCancelEventModal={onAdminCancelEventModalClose}
          userRole={userRole}
        />
      ) : null}
    </Main>
  );
};

export default withTheme(Planning);
