import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';
import { Theme } from '../../../theme';
import CustomTitle from '../../components/texts/CustomTitle';

import { MainArticle } from './style';

type Props = { title: string; children: any; theme: Theme };

const Article = (props: Props): ReactElement => {
  const { title, children, theme } = props;

  return (
    <MainArticle>
      <CustomTitle color={theme.text}>{title}</CustomTitle>
      {children}
    </MainArticle>
  );
};

export default withTheme(Article);
