import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const Main = styled.button`
  align-items: center;
  background-color: ${(props) => props.theme.classicButton};
  border: 0px solid;
  border-radius: 32px;
  display: flex;
  height: ${(props: { isPortrait: boolean }) =>
    isMobile ? (props.isPortrait ? '32vh' : '32vw') : '32vh'};
  justify-content: center;
  padding: 40px;
  width: ${(props: { isPortrait: boolean }) =>
    isMobile ? (props.isPortrait ? '32vh' : '32vw') : '32vh'};

  &:hover:enabled {
    cursor: pointer;
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    z-index: 1;
    transition: 200ms;
  }

  &:active:enabled {
    position: relative;
    top: 4px;
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export const Container = styled.div`
  align-items: center;
  color: ${(props) => props.theme.text};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 100%;
`;

export const SubTitle = styled.h4`
  margin: 0;
  padding: 0;
`;
