import React from 'react';

import Icon from '../icon/Icon';
import Loader from '../loader/Loader';
import CustomSubTitle from '../texts/CustomSubTitle';

import { Main } from './style';

type Props = {
  color: string;
  disabled?: boolean;
  forInputLabel: string;
  iconColor?: string;
  iconName: string;
  iconStyle?: React.CSSProperties;
  loading?: boolean;
  style?: React.CSSProperties;
  title: string;
};

const TextIconLabel = (props: Props): JSX.Element => {
  const {
    color,
    disabled = false,
    forInputLabel,
    iconColor,
    iconName,
    iconStyle,
    loading = false,
    style,
    title,
  } = props;
  const iconSize = '24px';

  return (
    <Main
      htmlFor={forInputLabel}
      id="TextIconLabel"
      color={color}
      disabled={disabled}
      style={style}
    >
      {loading ? (
        <Loader size={iconSize} style={iconStyle} />
      ) : (
        <Icon
          name={iconName}
          size={iconSize}
          color={iconColor || color}
          style={iconStyle}
        />
      )}
      <CustomSubTitle
        color={color}
        style={{ paddingLeft: 16, whiteSpace: 'nowrap' }}
      >
        {title}
      </CustomSubTitle>
    </Main>
  );
};

export default TextIconLabel;
