import React, { useEffect, useRef } from 'react';
import EditorJS from '@editorjs/editorjs';
import { withTheme } from 'styled-components';

import { Theme } from '../../../theme';

type Props = {
  data: any;
  holder: string;
  setArticle: (content: any) => void;
  tools: any;
  theme: Theme;
};

const Editor = (props: Props) => {
  const { data, holder, setArticle, tools, theme } = props;
  const editorInstance = useRef<EditorJS>();

  useEffect(() => {
    if (!editorInstance.current) {
      const editor = new EditorJS({
        holder: holder,
        minHeight: 54,
        readOnly: false,
        tools: tools,
        data: data,
        async onChange(api: any) {
          const data = await api.saver.save();
          setArticle(data);
        },
        placeholder: 'Commencer à écrire',
      });
      editorInstance.current = editor;
    }

    return () => {
      if (editorInstance.current?.destroy) {
        editorInstance.current.destroy();
      }
    };
  }, []);

  return (
    <div
      style={{
        backgroundColor: theme.editor.background,
        border: '2px solid',
        borderColor: theme.text,
        borderRadius: 8,
        padding: 8,
        width: '80%',
        maxWidth: 784,
        marginTop: 23,
        marginBottom: 8,
        color: theme.editor.text,
      }}
      id={holder}
      key={holder}
    />
  );
};

export default withTheme(Editor);
