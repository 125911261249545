import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import Paragraph from '../../../components/paragraph/Paragraph';
import { device, size } from '../../../constants/screenSizes';

export const Main = styled.div`
  width: 100%;

  @media ${device.laptop} {
    max-width: ${() => size.laptop}px;
  }
`;

export const SessionContainer = styled.div`
  border-radius: 16px;
  background-color: ${(props) =>
    props.color ?? props.theme.classicButton};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 32px 0;
  overflow: hidden;
  padding: 32px 24px 48px 24px;
  position: relative;
`;

export const OpenerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 0 32px 0;
`;

export const HeaderButtons = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding-top: 8px;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const Status = styled.div`
  background-color: ${(props) =>
    props.color ?? props.theme.classicButton};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 8px 0;
`;

export const SessionText = styled(Paragraph).attrs(
  (props: { isCanceled: boolean }) => ({
    style: {
      padding: '8px 0 8px 0',
      textAlign: 'left',
      textDecoration: props.isCanceled ? 'line-through' : 'none',
    },
  }),
)<{
  isCanceled: boolean;
}>``;
