import styled from 'styled-components';

type Props = { customSize: string };

export const Main = styled.div`
  display: flex;
  height: ${(props: Props) => props.customSize};
  padding: 0;
  margin: 0;
  width: ${(props: Props) => props.customSize};
`;
