import styled from 'styled-components';

import { device, size } from '../../../constants/screenSizes';

export const Main = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  @media ${device.laptop} {
    max-width: ${() => size.laptop}px;
  }
`;
