import styled from 'styled-components';

export const Main = styled.div<{
  disabled?: boolean;
  checked?: boolean;
}>`
  align-content: center;
  background-color: ${(props) =>
    props.checked ? props.theme.success : props.theme.danger};
  border-radius: 50px;
  width: 48px;
  height: 24px;
  padding: 2px;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  display: flex;
  justify-content: ${(props) =>
    props.checked ? 'flex-end' : 'flex-start'};

  &:hover {
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.classicButton};
  border-radius: 50px;
  height: 24px;
  width: 24px;
`;
