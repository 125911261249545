import React, { ReactElement, useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../theme';
import {
  baseUserUpdateWelcomeEmailSent,
  listenToAllBaseUsersList,
  listenToAllUsers,
  migrateUsersV2ToV3,
} from '../../firebase/user';
import { User, Users } from '../../types/users';
import { capitalizeFirstLetterForEachWord } from '../../utils/textDecoration';
import CustomPageTitle from '../../components/texts/CustomPageTitle';
import CustomTitle from '../../components/texts/CustomTitle';
import { EmailTemplate, sendEmail } from '../../utils/sendEmail';
import { ToastLevels, displayToast } from '../../utils/Toast';
import { TOAST_LEVELS } from '../../constants/toastLevels';
import TextIconButton from '../../components/textIconButton/TextIconButton';
import { icons } from '../../constants/icons';
import AddUserModal from './AddUserModal';
import DisableUserModal from './DisableUserModal';
import { useWindowDimensions } from '../../utils/useWindowDimensions';
import { size } from '../../constants/screenSizes';
import AdminUsersListLaptop from './adminUsersListComponent/AdminUsersListLaptop';
import AdminUsersListTablet from './adminUsersListComponent/AdminUsersListTablet';
import AdminUsersListMobile from './adminUsersListComponent/AdminUsersListMobile';
import AdminBaseUsersListMobile from './adminBaseUsersListComponent/AdminBaseUsersListMobile';
import AdminBaseUsersListTablet from './adminBaseUsersListComponent/AdminBaseUsersListTablet';
import AdminBaseUsersListLaptop from './adminBaseUsersListComponent/AdminBaseUsersListLaptop';

import { Header, Main } from './style';
import Alert from '../../components/alert/Alert';

type Props = { theme: Theme };

const Admin = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { theme } = props;

  const [baseUsers, setBaseUsers] = useState([] as any);
  const [users, setUsers] = useState([] as Users);
  const [loading, setLoading] = useState(true);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isDisableUserModalOpen, setIsDisableUserModalOpen] =
    useState(false);
  const [isMigrationModalOpen, setIsMigrationModalOpen] =
    useState(false);

  useEffect(() => {
    let unsubscribeUsers: any;
    let unsubscribeBaseUsers: any;

    try {
      unsubscribeUsers = listenToAllUsers((users) => {
        setUsers(users);
        setLoading(false);
      });

      unsubscribeBaseUsers = listenToAllBaseUsersList((baseUsers) => {
        setBaseUsers(baseUsers);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }

    return () => {
      if (unsubscribeUsers) unsubscribeUsers();
      if (unsubscribeBaseUsers) unsubscribeBaseUsers();
    };
  }, []);

  const sendFirstConnectionEmail = async (user: any) => {
    const to = {
      email: user.email,
      name: capitalizeFirstLetterForEachWord(user.firstName),
    };
    const options = { password: user.tmpPassword };

    try {
      const emailSent = await sendEmail(
        to,
        EmailTemplate.WelcomeEmail,
        options,
      );
      if (emailSent) {
        baseUserUpdateWelcomeEmailSent(
          user.uid,
          Math.round(Date.now() / 1000),
        );
      }
      displayToast(
        TOAST_LEVELS.SUCCESS as ToastLevels,
        `Envoi OK du mail pour ${user.email}`,
      );
    } catch (error) {
      displayToast(
        TOAST_LEVELS.ERROR as ToastLevels,
        `Echec d'envoi du mail pour ${user.email} => ${error}`,
        100000,
      );
    }
  };

  const onAddUserModalOpen = () => setIsAddUserModalOpen(true);
  const onAddUserModalClose = () => setIsAddUserModalOpen(false);

  const onMigrationModalOpen = () => setIsMigrationModalOpen(true);
  const onMigrationModalClose = () => setIsMigrationModalOpen(false);

  const onDisableUserModalOpen = (user: User) => {
    setCurrentUser(user);
    setIsDisableUserModalOpen(true);
  };
  const onDisableUserModalClose = () => {
    setCurrentUser(null);
    setIsDisableUserModalOpen(false);
  };

  const playMigration = async () => {
    try {
      await migrateUsersV2ToV3();
      onMigrationModalClose();
      displayToast(
        TOAST_LEVELS.SUCCESS as ToastLevels,
        t('ADMIN.MIGRATION_SUCCESS_TOAST'),
      );
    } catch (error) {
      console.error('Migration fails', error);
    }
  };

  const getBaseUsersListByScreenSize = () => {
    const props = {
      loading,
      sendFirstConnectionEmail,
      baseUsers,
    };
    if (width >= size.laptop)
      return <AdminBaseUsersListLaptop {...props} />;
    if (width >= size.tablet)
      return <AdminBaseUsersListTablet {...props} />;
    return <AdminBaseUsersListMobile {...props} />;
  };
  const getUsersListByScreenSize = () => {
    const props = {
      loading,
      onDisableUserModalOpen,
      users,
    };
    if (width >= size.laptop)
      return <AdminUsersListLaptop {...props} />;
    if (width >= size.tablet)
      return <AdminUsersListTablet {...props} />;
    return <AdminUsersListMobile {...props} />;
  };

  return (
    <Main>
      <CustomPageTitle
        color={theme.text}
        style={{ paddingBottom: 40 }}
      >
        {t('ADMIN.TITLE')}
      </CustomPageTitle>
      <Header>
        <TextIconButton
          color={theme.text}
          iconName={icons.ADD_CIRCLE_OUTLINE}
          onClick={onAddUserModalOpen}
          title={t('ADMIN.NEW_MEMBER')}
        />
        <TextIconButton
          color={theme.text}
          disabled={false}
          iconName={icons.BUILD_OUTLINE}
          onClick={onMigrationModalOpen}
          title={t('ADMIN.PLAY_MIGRATION_TITLE', {
            curentVersion: '2.0.0',
            newVersion: '3.0.0',
          })}
        />
      </Header>
      <CustomTitle color={theme.text} style={{ padding: '40px 0' }}>
        {t('ADMIN.BASE_USERS', { count: baseUsers.length })}
      </CustomTitle>
      {getBaseUsersListByScreenSize()}
      <CustomTitle color={theme.text} style={{ padding: '40px 0' }}>
        {t('ADMIN.MEMBERS', { count: users.length })}
      </CustomTitle>
      {getUsersListByScreenSize()}
      <AddUserModal
        isAddUserModalOpen={isAddUserModalOpen}
        closeAddUserModal={onAddUserModalClose}
      />
      {currentUser ? (
        <DisableUserModal
          isDisableUserModalOpen={isDisableUserModalOpen}
          closeDisableUserModal={onDisableUserModalClose}
          currentUser={currentUser}
        />
      ) : null}
      <Alert
        button={{
          color: theme.success,
          iconName: icons.CHECKMARK_OUTLINE,
          text: t('ADMIN.PLAY_MIGRATION'),
          action: () => {
            (async () => {
              await playMigration();
            })();
          },
        }}
        closeAction={() => onMigrationModalClose()}
        ismodalOpen={isMigrationModalOpen}
        message={t('ADMIN.MIGRATION_WARNING')}
        secondaryButton={{
          color: theme.danger,
          iconName: icons.CLOSE_OUTLINE,
          text: t('COMMON.CANCEL'),
          action: () => onMigrationModalClose(),
        }}
        title={t('ADMIN.PLAY_MIGRATION_TITLE', {
          curentVersion: '2.0.0',
          newVersion: '3.0.0',
        })}
      />
    </Main>
  );
};

export default withTheme(Admin);
