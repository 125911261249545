import { ReactElement, useState } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../../theme';
import { icons } from '../../../constants/icons';
import EditionModal from '../../../components/editionModal/EditionModal';
import Input from '../../../components/input/Input';
import { formatDate } from '../../../utils/date';
import { Event } from '../../../types/event';
import { ToastLevels, displayToast } from '../../../utils/Toast';
import { TOAST_LEVELS } from '../../../constants/toastLevels';
import {
  unsuscribeMemberToSession,
  updateEvent,
} from '../../../firebase/events';
import { Role } from '../../../types/users';
import TextIconButton from '../../../components/textIconButton/TextIconButton';
import { ROLE } from '../../../constants/role';
import { handleFirebaseErrors } from '../../../utils/errors';
import { errorCodes } from '../../../types/errors';

type Props = {
  closeAssignUserToEventModal: () => void;
  currentEvent: Event | null;
  isAssignUserToEventModalOpen: boolean;
  userRole: Role;
  userUid: string;
  theme: Theme;
};

const AssignUserToEventModal = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const {
    closeAssignUserToEventModal,
    currentEvent,
    isAssignUserToEventModalOpen,
    userRole,
    userUid,
    theme,
  } = props;

  const [inputFields, setInputFields] = useState({
    note: currentEvent!.note,
    sessionTheme: currentEvent!.theme,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e: any) => {
    setInputFields({
      ...inputFields,
      [e.target.name]: e.target.value,
    });
  };

  const close = () => {
    setInputFields({
      note: '',
      sessionTheme: '',
    });
    closeAssignUserToEventModal();
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (
        userRole === ROLE.MEMBER &&
        currentEvent!.status === 'canceled' // TODO make a constant for status
      ) {
        handleFirebaseErrors(errorCodes.PermissionDenied);
        return;
      }
      await updateEvent(
        currentEvent!.uid,
        inputFields.note,
        inputFields.sessionTheme,
      );
      displayToast(
        TOAST_LEVELS.SUCCESS as ToastLevels,
        t('PLANNING.SAVE_OK_TOAST'),
      );
      close();
    } catch (error: any) {
      handleFirebaseErrors(error.code || error.message);
      close();
    } finally {
      setLoading(false);
    }
  };

  const unsuscribeUserToEvent = async () => {
    setLoading(true);
    try {
      await unsuscribeMemberToSession(currentEvent!.uid, userUid);
      document.body.style.overflow = 'inherit';
      displayToast(
        TOAST_LEVELS.SUCCESS as ToastLevels,
        t('PLANNING.UNSUSCRIBE_OK_TOAST'),
      );
      close();
    } catch (error: any) {
      handleFirebaseErrors(error.code || error.message);
      close();
    } finally {
      setLoading(false);
    }
  };

  return (
    <EditionModal
      button={{
        color: theme.success,
        iconName: icons.CHECKMARK_OUTLINE,
        text: t('COMMON.SAVE'),
        action: onSubmit,
      }}
      closeAction={close}
      ismodalOpen={isAssignUserToEventModalOpen}
      loading={loading}
      secondaryButton={{
        color: theme.danger,
        iconName: icons.CLOSE_OUTLINE,
        text: t('COMMON.CANCEL'),
        action: close,
      }}
      title={t('PLANNING.SESSION_DATE', {
        date: formatDate(currentEvent!.date, 'dd/MM/yyyy'),
      })}
    >
      <Input
        color={theme.text}
        defaultValue={inputFields.sessionTheme}
        leftIconName={icons.TRAIN_OUTLINE}
        charLimit={50}
        onChange={handleChange}
        placeholder={t('PLANNING.THEME')}
        title={t('PLANNING.THEME')}
        type="text"
        value={inputFields.sessionTheme}
        name="sessionTheme"
      />
      <Input
        color={theme.text}
        defaultValue={inputFields.note}
        leftIconName={icons.DOCUMENT_TEXT_OUTLINE}
        charLimit={300}
        onChange={handleChange}
        placeholder={t('PLANNING.NOTE_PLACEHOLDER')}
        title={t('PLANNING.NOTE')}
        isTextArea={true}
        type="text"
        value={inputFields.note}
        name="note"
      />
      <TextIconButton
        color={theme.warning}
        iconName={icons.LOG_OUT_OUTLINE}
        loading={loading}
        onClick={() => unsuscribeUserToEvent()}
        title={t('PLANNING.UNSUSCRIBE_BUTTON')}
      />
    </EditionModal>
  );
};

export default withTheme(AssignUserToEventModal);
