export const LINKS = {
  FACEBOOK: 'https://www.facebook.com/profile.php?id=100089084300944',
  FLUX6663: 'https://github.com/flux6663',
  INSTA:
    'https://instagram.com/railclubmodelsat?igshid=ZDdkNTZiNTM%3D',
  MAPS: 'https://goo.gl/maps/dY2e5yT16SB1zDFW7',
  ODEXVY: 'https://github.com/odexvy',
  YOUTUBE: '',
  YOUTUBE_AIGUILLAGE:
    'https://www.youtube.com/channel/UCZ3xXAuhGLfZchx7IlV_LqA',
};
