import React from 'react';

import Icon from '../icon/Icon';
import Loader from '../loader/Loader';
import CustomSubTitle from '../texts/CustomSubTitle';

import { Main } from './style';

type Props = {
  color: string;
  disabled?: boolean;
  onClick: (e?: any) => void;
  style?: React.CSSProperties;
  title: string;
};

const TextButton = (props: Props): JSX.Element => {
  const { color, disabled = false, onClick, style, title } = props;

  return (
    <Main
      id="TextIconButton"
      color={color}
      disabled={disabled}
      onClick={onClick}
      style={style}
      type="button"
    >
      <CustomSubTitle color={color} style={{ whiteSpace: 'nowrap' }}>
        {title}
      </CustomSubTitle>
    </Main>
  );
};

export default TextButton;
