import React, { ReactElement, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import { ThemeProvider } from 'styled-components';
import { useAuthState } from 'react-firebase-hooks/auth';
import { ToastContainer } from 'react-toastify';
import Lottie from 'react-lottie';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';

import { routes } from './routes';
import { darkTheme, lightTheme } from '../../theme';
import { GlobalStyles } from '../components/theme/globalStyles';
import {
  useLocalStorage,
  useVolatileStorage,
} from '../utils/useLocalStorage';
import { auth } from '../firebase/connection';
import Core from '../components/core/Core';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import LogIn from '../screens/connection/login/LogIn';
import ResetPassword from '../screens/connection/resetPassword/ResetPassword';
import MainVisitor from '../screens/mainVisitor/MainVisitor';
import Menu from '../screens/menu/Menu';
import Planning from '../screens/planning/main/Planning';
import Settings from '../screens/settings/Settings';
import UserFinalization from '../screens/userFinalization/UserFinalization';
import MembersList from '../screens/membersList/MembersList';
import ScrollToTop from '../utils/ScrollToTop';
import Maintenance from '../screens/maintenance/Maintenance';
import { MAINTENANCE } from '../../maintenance';
import Version from '../screens/versions/Versions';
import Loader from '../components/loader/Loader';
import Error from '../screens/error/Error';
import Blog from '../screens/blog/main/Blog';
import BlogEdition from '../screens/blog/edition/BlogEdition';
import BlogDraft from '../screens/blog/draft/BlogDraft';
import Admin from '../screens/admin/Admin';
import { emailJSConfig } from '../../emailJSConfig';

import * as animationData from '../../assets/easterEgg.json';

import { Main } from './style';

const App = () => {
  useEffect(() => emailjs.init(emailJSConfig.publicApiKey), []);
  const { t } = useTranslation();

  const [activeTheme, setActiveTheme] = useLocalStorage(
    'theme',
    'light',
  );
  const [isEasterEggActivate, setIsEasterEggActivate] =
    useVolatileStorage('isEasterEggActivate', false);

  const themeToggler = () => {
    activeTheme === 'light'
      ? setActiveTheme('dark')
      : setActiveTheme('light');
  };
  const easterEggToggler = () => {
    setIsEasterEggActivate(!isEasterEggActivate);
  };
  const [user, loading, error] = useAuthState(auth);

  const navigateWithUserCheck = (routeComponent: ReactElement) => {
    // TODO add admin check
    // TODO add user disabled check (quite difficult)
    if (MAINTENANCE) return <Maintenance />;
    if (loading) return <Loader size={'48px'} />;
    if (error)
      return <Error code={500} message={t('ERROR.GLOBAL_ERROR')} />;
    if (!user) return <LoginRoute />;

    return routeComponent;
  };

  const navigateWithMaintenanceCheck = (
    routeComponent: ReactElement,
  ) => (!MAINTENANCE ? routeComponent : <Maintenance />);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <ThemeProvider
      theme={activeTheme === 'light' ? lightTheme : darkTheme}
    >
      <Router>
        <GlobalStyles />
        <Main isEasterEgg={isEasterEggActivate}>
          <Header
            easterEgg={{
              toggler: easterEggToggler,
              isEasterEggActivate,
            }}
          />
          <Core>
            <ScrollToTop />
            <Switch>
              <Route path={routes.LOGIN}>
                {navigateWithMaintenanceCheck(<LoginRoute />)}
              </Route>
              <Route path={routes.RESET_PWD}>
                {navigateWithMaintenanceCheck(<ResetPasswordRoute />)}
              </Route>
              <Route path={routes.USER_FINALIZATION}>
                {navigateWithUserCheck(<UserFinalizationRoute />)}
              </Route>
              <Route path={routes.MENU.ADMIN_SETTINGS}>
                {navigateWithUserCheck(<AdminRoute />)}
              </Route>
              <Route path={routes.MENU.MEMBERS}>
                {navigateWithUserCheck(<MembersRoute />)}
              </Route>
              <Route path={routes.MENU.SETTINGS}>
                {navigateWithUserCheck(
                  <SettingsRoute
                    activeTheme={activeTheme}
                    themeToggler={themeToggler}
                  />,
                )}
              </Route>
              <Route path={routes.MENU.PLANNING.MAIN}>
                {navigateWithUserCheck(<PlanningRoute />)}
              </Route>
              <Route path={routes.MENU.MAIN}>
                {navigateWithUserCheck(<MenuRoute />)}
              </Route>
              <Route path={routes.BLOG.DRAFT}>
                {navigateWithUserCheck(<BlogRouteDraft />)}
              </Route>
              <Route path={routes.BLOG.EDITION}>
                {navigateWithUserCheck(<BlogRouteEdition />)}
              </Route>
              <Route path={routes.BLOG.MAIN}>
                {navigateWithMaintenanceCheck(<BlogRoute />)}
              </Route>
              <Route path={routes.VERSIONS}>
                {navigateWithMaintenanceCheck(<VersionRoute />)}
              </Route>
              <Route path={routes.HOME}>
                {navigateWithMaintenanceCheck(<HomeRoute />)}
              </Route>
            </Switch>
            <ToastContainer />
          </Core>
          {isBrowser && <Footer />}
          {isEasterEggActivate ? (
            <Lottie
              speed={0.5}
              isClickToPauseDisabled={true}
              options={defaultOptions}
              height={400}
              width={'100%'}
              style={{ position: 'fixed', bottom: 0, zIndex: 1100 }}
            />
          ) : null}
        </Main>
      </Router>
    </ThemeProvider>
  );
};

const HomeRoute = () => <MainVisitor />;
const LoginRoute = () => <LogIn />;
const ResetPasswordRoute = () => <ResetPassword />;
const PlanningRoute = () => <Planning />;
const BlogRouteDraft = () => <BlogDraft />;
const BlogRouteEdition = () => <BlogEdition />;
const BlogRoute = () => <Blog />;
const AdminRoute = () => <Admin />;
const MembersRoute = () => <MembersList />;
const SettingsRoute = (props: {
  activeTheme: string;
  themeToggler: () => void;
}) => (
  <Settings
    activeTheme={props.activeTheme}
    themeToggler={props.themeToggler}
  />
);
const MenuRoute = () => <Menu />;
const UserFinalizationRoute = () => <UserFinalization />;
const VersionRoute = () => <Version />;

export default App;
