import React, { ReactElement, useState } from 'react';
import { isMobile } from 'react-device-detect';
import YouTube, { YouTubeProps } from 'react-youtube';
import { withTheme } from 'styled-components';

import { Theme } from '../../../theme';
import Loader from '../../components/loader/Loader';
import Paragraph from '../../components/paragraph/Paragraph';
import CustomSubTitle from '../../components/texts/CustomSubTitle';
import Link from '../../components/texts/Link';

import { YoutubePlaceHolder } from './style';

type Props = {
  description?: string;
  source?: { link: string; text: string };
  theme: Theme;
  title: string;
  videoId: string;
};

const YoutubePLayer = (props: Props): ReactElement => {
  const { description, source, theme, title, videoId } = props;
  const [loading, setLoading] = useState(true);
  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    setLoading(false);
    event.target.pauseVideo();
  };

  const opts: YouTubeProps['opts'] = {
    alignSelf: 'center',
    height: 392,
    width: '100%',
  };

  return (
    <div
      style={{
        width: isMobile ? '100%' : '60%',
        paddingTop: 32,
      }}
    >
      <CustomSubTitle
        color={theme.text}
        style={{ padding: '0 0 24px 0' }}
      >
        {title}
      </CustomSubTitle>
      {description ? (
        <Paragraph style={{ padding: '0 0 24px 0' }}>
          {description}
        </Paragraph>
      ) : null}
      {loading ? (
        <YoutubePlaceHolder theme={theme}>
          <Loader size="48px" />
        </YoutubePlaceHolder>
      ) : null}
      <YouTube
        loading="lazy"
        opts={opts}
        onReady={onPlayerReady}
        style={{ display: loading ? 'none' : 'inline' }}
        videoId={videoId}
      />

      {source ? (
        <Link color={theme.text} url={source.link}>
          {source.text}
        </Link>
      ) : null}
    </div>
  );
};

export default withTheme(YoutubePLayer);
