import React, { ReactElement, useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import CustomPageTitle from '../../../components/texts/CustomPageTitle';
import { icons } from '../../../constants/icons';
import { goTo, routes } from '../../../router/routes';
import { useHistory, useLocation } from 'react-router-dom';
import { BlogArticle, BlogArticles } from '../../../types/blog';
import {
  deleteBlogArticle,
  getAllDraftedBlogArticles,
} from '../../../firebase/blog';
import { formatDate } from '../../../utils/date';
import Alert from '../../../components/alert/Alert';
import CustomTitle from '../../../components/texts/CustomTitle';
import { ToastLevels, displayToast } from '../../../utils/Toast';
import { TOAST_LEVELS } from '../../../constants/toastLevels';
import IconButton from '../../../components/iconButton/IconButton';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../firebase/connection';
import Reader from '../../../components/editor/Reader';
import TextIconButton from '../../../components/textIconButton/TextIconButton';

import { Theme } from '../../../../theme';

import { Main } from './style';

type Props = { theme: Theme };

const BlogDraft = (props: Props): ReactElement => {
  const { theme } = props;

  const history = useHistory();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const [user] = useAuthState(auth);

  const [articles, setArticles] = useState<BlogArticles>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isDeleteAvatarModalOpen, setIsDeleteAvatarModalOpen] =
    useState<Partial<BlogArticle> | false>(false);

  useEffect(() => {
    getAllDraftedBlogArticles(user?.uid ? user.uid : '')
      .then((a) => {
        setArticles(
          a.map((a) => ({
            ...a,
            message: a.message.replaceAll(
              `color='#000000'`,
              `color='${theme.text}'`,
            ),
          })),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const goToArticleEdition = (article?: BlogArticle) => {
    goTo(
      location,
      history,
      routes.BLOG.EDITION,
      false,
      article ?? null,
    );
  };

  const goToArticle = () => {
    goTo(location, history, routes.BLOG.MAIN, false);
  };

  const deleteArticle = async (uuid: string): Promise<void> => {
    try {
      await deleteBlogArticle(uuid);
    } catch (error) {
      setIsDeleteAvatarModalOpen(false);
      return;
    }
    setArticles(articles.filter((article) => article.uid !== uuid));
    displayToast(
      TOAST_LEVELS.SUCCESS as ToastLevels,
      'Suppression réussie',
    );
    setIsDeleteAvatarModalOpen(false);
  };

  return (
    <>
      <Main>
        <CustomPageTitle
          color={theme.text}
          style={{ paddingBottom: 32, textAlign: 'center' }}
        >
          {'Articles en brouillon'}
        </CustomPageTitle>
        <div style={{ display: 'flex', gap: 16 }}>
          <TextIconButton
            color={theme.text}
            iconName={icons.ADD_CIRCLE_OUTLINE}
            onClick={() => goToArticleEdition()}
            title={'Ecrire un article'}
            style={{ marginBottom: 40 }}
          />
          <TextIconButton
            color={theme.text}
            iconName={icons.CLOUD_UPLOAD_OUTLINE}
            onClick={() => goToArticle()}
            title={'Acceder aux articles publiés'}
            style={{ marginBottom: 40 }}
          />
        </div>
        {articles.map((article: BlogArticle) => (
          <div
            key={article.uid}
            style={{
              backgroundColor: theme.classicButton,
              borderRadius: 8,
              padding: 24,
              width: '80%',
              marginBottom: 24,
              color: theme.text,
            }}
          >
            <CustomTitle
              color={theme.info}
              style={{ paddingBottom: 24 }}
            >
              {article.title}
            </CustomTitle>
            <Reader
              data={JSON.parse(article.message)}
              holder={article.uid}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                paddingTop: 24,
                flexWrap: 'wrap',
                flexDirection: 'column',
                gap: 16,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: 16,
                  alignItems: 'center',
                  paddingTop: 24,
                  flexWrap: 'wrap',
                }}
              >
                <IconButton
                  isbordered={true}
                  color={theme.danger}
                  iconName={icons.TRASH_OUTLINE}
                  onClick={() => setIsDeleteAvatarModalOpen(article)}
                />
                <IconButton
                  isbordered={true}
                  color={theme.text}
                  iconName={icons.CREATE_OUTLINE}
                  onClick={() => goToArticleEdition(article)}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  textAlign: 'right',
                  fontStyle: 'italic',
                  gap: 16,
                }}
              >
                <text>{article.simplifiedAuthor}</text>
                <text>
                  {formatDate(
                    article.createdDate,
                    'dd/MM/yyyy - HH:mm',
                  )}
                </text>
                <text>
                  {formatDate(
                    article.postedDate,
                    'dd/MM/yyyy - HH:mm',
                  )}
                </text>
              </div>
            </div>
          </div>
        ))}
      </Main>
      <Alert
        button={{
          color: theme.success,
          iconName: icons.CHECKMARK_OUTLINE,
          text: t('COMMON.CONFIRM'),
          action: () => {
            (async () => {
              await deleteArticle(isDeleteAvatarModalOpen.uid);
            })();
          },
        }}
        closeAction={() => setIsDeleteAvatarModalOpen(false)}
        ismodalOpen={!!isDeleteAvatarModalOpen}
        message={'delete this article'}
        secondaryButton={{
          color: theme.danger,
          iconName: icons.CLOSE_OUTLINE,
          text: t('COMMON.CANCEL'),
          action: () => setIsDeleteAvatarModalOpen(false),
        }}
        title={isDeleteAvatarModalOpen.title}
      />
    </>
  );
};

export default withTheme(BlogDraft);
