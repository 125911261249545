import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';

import { Events } from '../types/event';
import { db } from './connection';
import {
  decrementSessionsCounter,
  getUserFullName,
  incrementSessionsCounter,
} from './user';
import { Role } from '../types/users';
import { TOAST_LEVELS } from '../constants/toastLevels';
import { ToastLevels, displayToast } from '../utils/Toast';
import { ROLE } from '../constants/role';
import { errorCodes } from '../types/errors';

/**
 * @deprecated To rework between 2033 and 2034
 */
export const CSVEventListToFirestore = async (csvEvent: any) => {
  const docRef = doc(db, 'events', csvEvent.id);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    await setDoc(docRef, {
      date: csvEvent.date,
      note: csvEvent.note || '',
      uid: csvEvent.id,
      theme: csvEvent.theme || '',
      assignedMember: csvEvent.assignedMember || '',
      simplifiedAssignedMember:
        csvEvent.simplifiedAssignedMember || '',
      status: csvEvent.status,
      type: csvEvent.type,
      presentMembers: arrayUnion(),
      cancelInfo: '',
    });
  } else {
    await setDoc(docRef, {
      date: csvEvent.date,
      note: csvEvent.note,
      uid: csvEvent.id,
      theme: csvEvent.theme,
      assignedMember: csvEvent.assignedMember,
      simplifiedAssignedMember: csvEvent.simplifiedAssignedMember,
      status: csvEvent.status,
      type: csvEvent.type,
      presentMembers: arrayUnion(),
      cancelInfo: '',
    });
  }
};

export const listenToNextMonthEvents = (
  callback: (events: Events) => void,
) => {
  const startDate = Math.round(Date.now() / 1000) - 345600;
  const endDate = Math.round(Date.now() / 1000) + 5184000;
  const eventsQuery = query(
    collection(db, 'events'),
    where('date', '>=', startDate),
    where('date', '<=', endDate),
  );

  const unsubscribe = onSnapshot(eventsQuery, (querySnapshot) => {
    let events: Events = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      events.push({
        date: data.date,
        note: data.note,
        uid: data.uid,
        theme: data.theme,
        assignedMember: data.assignedMember,
        simplifiedAssignedMember: data.simplifiedAssignedMember,
        status: data.status,
        type: data.type,
        presentMembers: data.presentMembers,
        cancelInfo: data.cancelInfo,
      });
    });

    callback(events);
  });

  return unsubscribe;
}; //TODO try catch

export const getNextAssignedEventsIds = async (
  userId: string,
): Promise<string[]> => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(db, 'events'),
        where('date', '>=', Math.round(Date.now() / 1000)),
        where('date', '<=', Math.round(Date.now() / 1000) + 5184000),
        where('assignedMember', '==', userId),
      ),
    );
    let ids: string[] = [];

    querySnapshot.forEach((doc: any) => {
      const data = doc.data();
      ids.push(data.uid);
    });

    return ids;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const suscribMemberOnSession = async (
  eventID: string,
  userId: string,
) => {
  if (!userId) throw new Error(errorCodes.UserNotFound);

  try {
    const docRef = doc(db, 'events', eventID);
    const docSnap = await getDoc(docRef);
    const userName = await getUserFullName(userId);

    if (!docSnap.exists()) throw new Error(errorCodes.EventNotFound);

    await updateDoc(docRef, {
      assignedMember: userId,
      simplifiedAssignedMember: userName,
      status: 'validated', // TODO make a constant for status
    });
    await incrementSessionsCounter(userId);
  } catch (error: any) {
    throw new Error(error);
  }
};

export const unsuscribeMemberToSession = async (
  eventID: string,
  userUid: string,
) => {
  try {
    const docRef = doc(db, 'events', eventID);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) throw new Error(errorCodes.EventNotFound);

    await updateDoc(docRef, {
      assignedMember: '',
      note: '',
      presentMembers: [],
      status: 'pending', // TODO make a constant for status
      simplifiedAssignedMember: '',
      theme: '',
    });
    await decrementSessionsCounter(userUid);
  } catch (error: any) {
    throw new Error(error);
  }
};

export const adminCancelEvent = async (
  eventID: string,
  cancelInfo: string,
  userRole: Role,
) => {
  try {
    if (userRole !== ROLE.MEMBER) {
      const docRef = doc(db, 'events', eventID);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists())
        throw new Error(errorCodes.EventNotFound);

      await updateDoc(docRef, {
        status: 'canceled', // TODO make a constant for status
        cancelInfo: cancelInfo,
      });
    } else {
      throw new Error(errorCodes.PermissionDenied);
    }
  } catch (error: any) {
    throw new Error(error);
  }
};

export const adminReActivateEvent = async (
  eventID: string,
  isValidated: boolean,
) => {
  try {
    const docRef = doc(db, 'events', eventID);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) throw new Error(errorCodes.EventNotFound);

    await updateDoc(docRef, {
      status: isValidated ? 'validated' : 'pending', // TODO make a constant for status
      cancelInfo: '',
    });
  } catch (error: any) {
    throw new Error(error);
  }
};

export const updateEvent = async (
  eventUid: string,
  note: string,
  sessionTheme: string,
) => {
  try {
    const docRef = doc(db, 'events', eventUid);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) throw new Error(errorCodes.EventNotFound);

    await updateDoc(docRef, {
      note: note,
      theme: sessionTheme,
    });
  } catch (error: any) {
    throw new Error(error);
  }
};

/**
 * @deprecated Not used, to review
 */
export const addMemberToPresentMembers = async (
  eventUid: string,
  memberToAdd: string,
) => {
  const docRef = doc(db, 'events', eventUid);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) throw new Error(errorCodes.EventNotFound);

  await updateDoc(docRef, {
    presentMembers: arrayUnion(memberToAdd),
  });
};

/**
 * @deprecated Not used, to review
 */
export const removeMemberToPresentMembers = async (
  eventUid: string,
  memberToRemove: string,
) => {
  const docRef = doc(db, 'events', eventUid);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) throw new Error(errorCodes.EventNotFound);

  await updateDoc(docRef, {
    presentMembers: arrayRemove(memberToRemove),
  });
};
