import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { device } from '../../constants/screenSizes';

export const Main = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const Grid = styled.div`
  display: grid;
  gap: 32px;

  grid-template-columns: ${(props: { isPortrait: boolean }) =>
    isMobile
      ? props.isPortrait
        ? 'repeat(1, 32vh)'
        : 'repeat(1, 32vw)'
      : 'repeat(1, 32vh)'};
  grid-template-rows: auto;

  @media ${device.tablet} {
    grid-template-columns: ${(props: { isPortrait: boolean }) =>
      isMobile
        ? props.isPortrait
          ? 'repeat(2, 32vh)'
          : 'repeat(2, 32vw)'
        : 'repeat(2, 32vh)'};
  }
  @media ${device.laptop} {
    grid-template-columns: ${(props: { isPortrait: boolean }) =>
      isMobile
        ? props.isPortrait
          ? 'repeat(3, 32vh)'
          : 'repeat(3, 32vw)'
        : 'repeat(3, 32vh)'};
  }
`;
