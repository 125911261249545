import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../../theme';
import Loader from '../../../components/loader/Loader';
import {
  capitalizeFirstLetterForEachWord,
  displayPhoneNumberClearly,
} from '../../../utils/textDecoration';
import { formatDate } from '../../../utils/date';
import TextButton from '../../../components/textButton/TextButton';
import Tag from '../../../components/tag/Tag';
import { ROLE } from '../../../constants/role';
import Paragraph from '../../../components/paragraph/Paragraph';
import { User, Users } from '../../../types/users';

import {
  UserDataRow,
  Info,
  Mail,
  Main,
  FirstMailGridCase,
} from '../style';

type Props = {
  loading: boolean;
  onDisableUserModalOpen: (user: User) => void;
  theme: Theme;
  users: Users;
};

const AdminUsersListLaptop = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const { loading, onDisableUserModalOpen, theme, users } = props;

  if (loading) return <Loader size="48px" />;

  return (
    <Main>
      <UserDataRow color={theme.text} isFirst={true}>
        <Info header={true}>{t('COMMON.LAST_NAME')}</Info>
        <Info header={true}>{t('COMMON.EMAIL')}</Info>
        <Info header={true}>{t('COMMON.PHONE')}</Info>
        <Info header={true}>{t('COMMON.MOBILE_PHONE')}</Info>
        <Info header={true}>{t('COMMON.MEMBER_SINCE')}</Info>
        <Info header={true}>{t('COMMON.STATUS')}</Info>
        <Info header={true}>{t('COMMON.STATUS')}</Info>
      </UserDataRow>
      {users
        .sort((a: User, b: User) =>
          a.lastName.localeCompare(b.lastName),
        )
        .map((user: User, index: number) => (
          <UserDataRow
            key={`${user.uid}-row`}
            color={theme.text}
            isLast={index === users.length - 1}
          >
            <Tag
              color={theme.text}
              text={capitalizeFirstLetterForEachWord(
                `${user.firstName} ${user.lastName}`,
              )}
              userUid={user.uid}
            />
            <Mail
              color={theme.text}
              href={`mailto:${user.email}`}
              key={`${user.uid}-mail`}
            >
              {user.email ?? '-'}
            </Mail>
            <Info key={`${user.uid}-phoneNumber`}>
              {displayPhoneNumberClearly(user.phoneNumber) ?? '-'}
            </Info>
            <Info key={`${user.uid}-smartphoneNumber`}>
              {displayPhoneNumberClearly(user.smartphoneNumber) ??
                '-'}
            </Info>
            <Info key={`${user.uid}-memberSince`}>
              {formatDate(user.memberSince, 'yyyy') ?? '-'}
            </Info>
            <Info key={`${user.uid}-role`}>{user.role ?? '-'}</Info>
            <Info key={`${user.uid}-disabled`}>
              {user.disabled ? (
                <FirstMailGridCase>
                  <Paragraph style={{ padding: 0 }}>{`❌ ${formatDate(
                    user.disabled,
                    'dd/MM/yyyy - HH:mm',
                  )}`}</Paragraph>
                  <TextButton
                    color={theme.success}
                    onClick={() => onDisableUserModalOpen(user)}
                    title={'Réactiver'} // TODO translate
                  />
                </FirstMailGridCase>
              ) : (
                <FirstMailGridCase>
                  <Paragraph style={{ padding: 0 }}>
                    {/* TODO translate */}
                    {`✅ Actif`}
                  </Paragraph>
                  <TextButton
                    disabled={
                      user.role === ROLE.ADMIN ||
                      user.role === ROLE.OFFICE
                    }
                    color={theme.danger}
                    onClick={() => onDisableUserModalOpen(user)}
                    title={'Désactiver'} // TODO translate
                  />
                </FirstMailGridCase>
              )}
            </Info>
          </UserDataRow>
        ))}
    </Main>
  );
};

export default withTheme(AdminUsersListLaptop);
