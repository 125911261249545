import { ReactElement, useState } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../theme';
import { icons } from '../../constants/icons';
import EditionModal from '../../components/editionModal/EditionModal';
import Input from '../../components/input/Input';
import { AddBaseUser } from '../../firebase/user';
import { validateEmail } from '../../utils/regexValidation';
import { handleFirebaseErrors } from '../../utils/errors';
import { errorCodes } from '../../types/errors';
import { displayToast, ToastLevels } from '../../utils/Toast';
import { TOAST_LEVELS } from '../../constants/toastLevels';

type Props = {
  closeAddUserModal: () => void;
  isAddUserModalOpen: boolean;
  theme: Theme;
};

const AddUserModal = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const { closeAddUserModal, isAddUserModalOpen, theme } = props;

  const [loading, setLoading] = useState(false);
  const [inputFields, setInputFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const handleChange = (e: any) => {
    setErrors({
      ...errors,
      [e.target.name]: '',
    });
    setInputFields({
      ...inputFields,
      [e.target.name]: e.target.value,
    });
  };

  const close = () => {
    setInputFields({
      firstName: '',
      lastName: '',
      email: '',
    });
    setErrors({
      firstName: '',
      lastName: '',
      email: '',
    });
    closeAddUserModal();
  };

  const validateValues = (inputValues: {
    firstName: string;
    lastName: string;
    email: string;
  }) => {
    let err = {
      firstName: '',
      lastName: '',
      email: '',
    };
    if (!inputValues.firstName) {
      handleFirebaseErrors(
        errorCodes.EmptyFirstName,
        (x) => (err.firstName = x),
      );
    }
    if (!inputValues.lastName) {
      handleFirebaseErrors(
        errorCodes.EmptyLastName,
        (x) => (err.lastName = x),
      );
    }
    if (!inputValues.email) {
      handleFirebaseErrors(
        errorCodes.EmptyEmail,
        (x) => (err.email = x),
      );
    }
    if (!validateEmail(inputValues.email)) {
      handleFirebaseErrors(
        errorCodes.InvalidEmail,
        (x) => (err.email = x),
      );
    }
    setErrors(err);

    return err;
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (
        Object.values(validateValues(inputFields)).every(
          (err) => err === null || err === '',
        )
      ) {
        await AddBaseUser({
          firstName: inputFields.firstName,
          lastName: inputFields.lastName,
          email: inputFields.email,
        });
        displayToast(
          TOAST_LEVELS.SUCCESS as ToastLevels,
          `Utilisateur ${inputFields.email} créé avec succès`, //TODO trad
        );
        close();
      }
    } catch (error: any) {
      handleFirebaseErrors(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <EditionModal
      button={{
        color: theme.success,
        iconName: icons.CHECKMARK_OUTLINE,
        text: t('COMMON.CONFIRM'),
        action: onSubmit,
      }}
      closeAction={close}
      ismodalOpen={isAddUserModalOpen}
      loading={loading}
      secondaryButton={{
        color: theme.danger,
        iconName: icons.CLOSE_OUTLINE,
        text: t('COMMON.CANCEL'),
        action: close,
      }}
      title={t('ADMIN.NEW_MEMBER')}
    >
      <Input
        color={theme.text}
        error={errors.firstName}
        placeholder={t('COMMON.FIRST_NAME')}
        title={t('COMMON.FIRST_NAME')}
        type="text"
        onChange={handleChange}
        value={inputFields.firstName}
        required={true}
        name="firstName"
      />
      <Input
        color={theme.text}
        error={errors.lastName}
        placeholder={t('COMMON.LAST_NAME')}
        title={t('COMMON.LAST_NAME')}
        type="text"
        onChange={handleChange}
        value={inputFields.lastName}
        required={true}
        name="lastName"
      />
      <Input
        color={theme.text}
        error={errors.email}
        placeholder={t('COMMON.EMAIL')}
        title={t('COMMON.EMAIL')}
        type="email"
        onChange={handleChange}
        value={inputFields.email}
        required={true}
        name="email"
      />
    </EditionModal>
  );
};

export default withTheme(AddUserModal);
