import { ReactElement, useState } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../theme';
import { icons } from '../../constants/icons';
import EditionModal from '../../components/editionModal/EditionModal';
import Input from '../../components/input/Input';
import { validatePhoneNumber } from '../../utils/regexValidation';
import { displayToast, ToastLevels } from '../../utils/Toast';
import { TOAST_LEVELS } from '../../constants/toastLevels';
import { handleFirebaseErrors } from '../../utils/errors';
import { changeUserInfo } from '../../firebase/user';
import { errorCodes } from '../../types/errors';

type Props = {
  closeChangeUserInfoModal: () => void;
  isChangeUserInfoModalOpen: boolean;
  phoneNumber: string;
  smartphoneNumber: string;
  userId: string;
  theme: Theme;
};

const ChangeUserInfoModal = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const {
    closeChangeUserInfoModal,
    isChangeUserInfoModalOpen,
    phoneNumber,
    smartphoneNumber,
    userId,
    theme,
  } = props;

  const [loading, setLoading] = useState(false);
  const [inputFields, setInputFields] = useState({
    phoneNumber: phoneNumber ?? '',
    smartphoneNumber: smartphoneNumber ?? '',
  });
  const [errors, setErrors] = useState({
    phoneNumber: '',
    smartphoneNumber: '',
  });

  const handleChange = (e: any) => {
    setErrors({
      ...errors,
      [e.target.name]: '',
    });
    setInputFields({
      ...inputFields,
      [e.target.name]: e.target.value,
    });
  };

  const close = () => {
    setInputFields({
      phoneNumber: '',
      smartphoneNumber: '',
    });
    setErrors({
      phoneNumber: '',
      smartphoneNumber: '',
    });
    closeChangeUserInfoModal();
  };

  const validateValues = (inputValues: {
    phoneNumber: string;
    smartphoneNumber: string;
  }) => {
    let err = {
      phoneNumber: '',
      smartphoneNumber: '',
    };
    if (inputValues.phoneNumber) {
      if (!validatePhoneNumber(inputValues.phoneNumber)) {
        handleFirebaseErrors(
          errorCodes.InvalidPhoneNumber,
          (x) => (err.phoneNumber = x),
        );
      }
    }
    if (inputValues.smartphoneNumber) {
      if (!validatePhoneNumber(inputValues.smartphoneNumber)) {
        handleFirebaseErrors(
          errorCodes.InvalidPhoneNumber,
          (x) => (err.smartphoneNumber = x),
        );
      }
    }

    setErrors(err);

    return err;
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (
        Object.values(validateValues(inputFields)).every(
          (err) => err === null || err === '',
        )
      ) {
        await changeUserInfo(
          userId,
          inputFields.phoneNumber,
          inputFields.smartphoneNumber,
        );
        setInputFields({
          phoneNumber: '',
          smartphoneNumber: '',
        });
        close();
        displayToast(
          TOAST_LEVELS.SUCCESS as ToastLevels,
          t('SETTINGS.CHANGE_INFO_SUCCESS'),
        );
      }
    } catch (error: any) {
      handleFirebaseErrors(error.code || error.message, (x) =>
        setErrors({ ...errors, smartphoneNumber: x }),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <EditionModal
      button={{
        color: theme.success,
        iconName: icons.CHECKMARK_OUTLINE,
        text: t('COMMON.CONFIRM'),
        action: onSubmit,
      }}
      closeAction={close}
      ismodalOpen={isChangeUserInfoModalOpen}
      loading={loading}
      secondaryButton={{
        color: theme.danger,
        iconName: icons.CLOSE_OUTLINE,
        text: t('COMMON.CANCEL'),
        action: close,
      }}
      title={t('SETTINGS.CHANGE_INFO')}
    >
      <Input
        color={theme.text}
        disabled={loading}
        error={errors.phoneNumber}
        leftIconName={icons.CALL_OUTLINE}
        onChange={handleChange}
        placeholder={t('COMMON.PHONE')}
        title={t('COMMON.PHONE')}
        value={inputFields.phoneNumber}
        name="phoneNumber"
        type="tel"
      />
      <Input
        color={theme.text}
        disabled={loading}
        error={errors.smartphoneNumber}
        leftIconName={icons.PHONE_OUTLINE}
        onChange={handleChange}
        placeholder={t('COMMON.MOBILE_PHONE')}
        title={t('COMMON.MOBILE_PHONE')}
        value={inputFields.smartphoneNumber}
        name="smartphoneNumber"
        type="tel"
      />
    </EditionModal>
  );
};

export default withTheme(ChangeUserInfoModal);
