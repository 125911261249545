import React, { ReactElement, useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../theme';
import { listenToAllUsers } from '../../firebase/user';
import { Users } from '../../types/users';
import Loader from '../../components/loader/Loader';
import CustomPageTitle from '../../components/texts/CustomPageTitle';
import { useWindowDimensions } from '../../utils/useWindowDimensions';
import MembersListLaptopView from './MembersListLaptopView';
import { size } from '../../constants/screenSizes';
import MembersListMobileView from './MembersListMobileView';
import MembersListTabletView from './MembersListTabletView';

import { Main } from './style';

type Props = { theme: Theme };

const MembersList = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { theme } = props;

  const [users, setUsers] = useState([] as Users);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unsubscribeUsers: any;

    try {
      unsubscribeUsers = listenToAllUsers((users) => {
        setUsers(
          users
            .filter((u) => !u.disabled)
            .sort((a, b) => a.lastName.localeCompare(b.lastName)),
        );
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }

    return () => {
      if (unsubscribeUsers) unsubscribeUsers();
    };
  }, []);

  const getRenderByScreenSize = () => {
    if (width >= size.laptop)
      return <MembersListLaptopView users={users} />;
    if (width >= size.tablet)
      return <MembersListTabletView users={users} />;
    return <MembersListMobileView users={users} />;
  };

  if (loading) return <Loader size="48px" />;

  return (
    <Main>
      <CustomPageTitle
        color={theme.text}
        style={{ paddingBottom: 40 }}
      >
        {t('MEMBERS_LIST.TITLE', { count: users.length })}
      </CustomPageTitle>
      {getRenderByScreenSize()}
    </Main>
  );
};

export default withTheme(MembersList);
