import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { withTheme } from 'styled-components';

import CustomPageTitle from '../../components/texts/CustomPageTitle';
import { Theme } from '../../../theme';
import CustomSubTitle from '../../components/texts/CustomSubTitle';
import Icon from '../../components/icon/Icon';
import { icons } from '../../constants/icons';

import { Main } from './style';

type Props = {
  code: number;
  message: string;
  theme: Theme;
};

const Error = (props: Props): ReactElement => {
  const location = useLocation();
  const { code, message, theme } = props;

  return (
    <Main id={location.pathname}>
      <Icon
        color={theme.danger}
        name={icons.WARNING_OUTLINE}
        size="100px"
        style={{ paddingBottom: 24 }}
      />
      <CustomPageTitle
        color={theme.text}
        style={{ paddingBottom: 40 }}
      >
        {code.toString()}
      </CustomPageTitle>
      <CustomSubTitle color={theme.text}>{message}</CustomSubTitle>
    </Main>
  );
};

export default withTheme(Error);
