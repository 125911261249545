export const routes = {
  BLOG: {
    DRAFT: '/blog/draft',
    EDITION: '/blog/edition',
    MAIN: '/blog/all',
  },
  HOME: '/',
  LOGIN: '/login',
  MENU: {
    MAIN: '/menu',
    ADMIN_SETTINGS: '/menu/adminSettings',
    MEMBERS: '/menu/members',
    PLANNING: {
      MAIN: '/menu/planning/all',
    },
    SETTINGS: '/menu/settings',
  },
  RESET_PWD: '/reset-password',
  USER_FINALIZATION: '/userFinalization',
  VERSIONS: '/versions',
};

export const goTo = (
  location: any,
  history: any,
  route: string,
  replace?: boolean,
  params?: any,
) => {
  return (
    location.pathname !== route &&
    (replace
      ? history.replace(route, params)
      : history.push(route, params))
  );
};
