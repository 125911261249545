export const icons = {
  ADD_CIRCLE_OUTLINE: 'addCircleOutline',
  ARROW_BACK_OUTLINE: 'ArrowBackOutline',
  BOOK_OUTLINE: 'BookOutline',
  BUILD_OUTLINE: 'BuildOutline',
  CALENDAR_OUTLINE: 'CalendarOutline',
  CALL_OUTLINE: 'CallOutline',
  CAR_OUTLINE: 'CarOutline',
  CHATBOX_ELLIPSES_OUTLINE: 'ChatboxEllipsesOutline',
  CHECKMARK_OUTLINE: 'CheckmarkOutline',
  CLOSE_OUTLINE: 'closeOutline',
  CLOUD_UPLOAD_OUTLINE: 'cloudUploadOutline',
  CREATE_OUTLINE: 'createOutline',
  DOCUMENT_TEXT_OUTLINE: 'DocumentTextOutline',
  EYE_OFF_OUTLINE: 'EyeOffOutline',
  EYE_OUTLINE: 'EyeOutline',
  FACEBOOK: 'facebook',
  GIT_BRANCH_OUTLINE: 'gitBranchOutline',
  GIT_MERGE_OUTLINE: 'gitMergeOutline',
  HOUR_GLASS_OUTLINE: 'HourglassOutline',
  IMAGE_OUTLINE: 'ImageOutline',
  INFORMATION_OUTLINE: 'informationOutline',
  INSTAGRAM: 'instagram',
  LANGUAGE_OUTLINE: 'languageOutline',
  LOCK_CLOSED_OUTLINE: 'LockClosedOutline',
  LOG_OUT_OUTLINE: 'LogOutOutline',
  MAIL_OUTLINE: 'MailOutline',
  MENU_OUTLINE: 'MenuOutline',
  MOON_OUTLINE: 'MoonOutline',
  NOTIFICATIONS_OUTLINE: 'NotificationsOutline',
  PEOPLE_OUTLINE: 'peopleOutline',
  PERSON_ADD_OUTLINE: 'personAddOutline',
  PERSON_CIRCLE_OUTLINE: 'personOutline',
  PHONE_OUTLINE: 'phoneOutline',
  READER_OUTLINE: 'readerOutline',
  REFRESH_OUTLINE: 'RefreshOutline',
  SAVE_OUTLINE: 'saveOutline',
  SETTINGS_OUTLINE: 'SettingsOutline',
  SUN_OUTLINE: 'SunOutline',
  TEXT_OUTLINE: 'tetx-outline',
  TRAIN_OUTLINE: 'trainOutline',
  TRASH_OUTLINE: 'trashOutline',
  WARNING_OUTLINE: 'warningOutline',
  YOUTUBE: 'youtube',
};
