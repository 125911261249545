import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../theme';
import Icon from '../icon/Icon';
import { icons } from '../../constants/icons';
import CustomSubTitle from '../texts/CustomSubTitle';

import { Main } from './style';

type Props = {
  theme: Theme;
  style?: React.CSSProperties;
};

const InConstruction = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const { theme, style } = props;

  return (
    <Main style={style}>
      <Icon
        color={theme.warning}
        name={icons.WARNING_OUTLINE}
        size="48px"
      />
      <CustomSubTitle color={theme.text}>
        {t('IN_CONSTRUCTION.TEXT')}
      </CustomSubTitle>
    </Main>
  );
};

export default withTheme(InConstruction);
