import styled from 'styled-components';

export const WrapperContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 0 16px;
`;
export const LegendContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin: 8px;
`;
export const WrapperImg = styled.img<{ maxHeight?: number }>`
  border-radius: 7px;
  max-height: ${(props) =>
    props.maxHeight ? `${props.maxHeight}px` : 'none'};
  max-width: 100%;

  &:hover {
    cursor: pointer;
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transition: 200ms;
  }
`;

export const ModalImg = styled.img`
  border-radius: 7px;
  object-fit: scale-down;
  border-radius: 16px;
`;
