import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';

import { Theme } from '../../../theme';
import { memberRoleTranslation } from '../../constants/role';
import {
  capitalizeFirstLetterForEachWord,
  displayPhoneNumberClearly,
} from '../../utils/textDecoration';
import { formatDate } from '../../utils/date';
import Tag from '../../components/tag/Tag';
import { Users } from '../../types/users';
import Icon from '../../components/icon/Icon';
import { icons } from '../../constants/icons';

import { Info, InfoWithIcon, Mail, Main, MemberCard } from './style';

type Props = { users: Users; theme: Theme };

const MembersListMobileView = (props: Props): ReactElement => {
  const { users, theme } = props;

  return (
    <Main>
      {users.map((user, index: number) => (
        <MemberCard key={`${user.uid}-row`}>
          <InfoWithIcon>
            <Icon
              color={theme.text}
              size={'24px'}
              name={icons.MAIL_OUTLINE}
              style={{ paddingRight: 8 }}
            />
            <Mail
              color={theme.text}
              href={`mailto:${user.email}`}
              key={`${user.uid}-mail`}
            >
              {user.email ?? '-'}
            </Mail>
          </InfoWithIcon>
          <InfoWithIcon>
            <Icon
              color={theme.text}
              size={'24px'}
              name={icons.CALL_OUTLINE}
              style={{ paddingRight: 8 }}
            />
            <Info key={`${user.uid}-phoneNumber`}>
              {displayPhoneNumberClearly(user.phoneNumber) ?? '-'}
            </Info>
          </InfoWithIcon>
          <InfoWithIcon>
            <Icon
              color={theme.text}
              size={'24px'}
              name={icons.PHONE_OUTLINE}
              style={{ paddingRight: 8 }}
            />
            <Info key={`${user.uid}-smartphoneNumber`}>
              {displayPhoneNumberClearly(user.smartphoneNumber) ??
                '-'}
            </Info>
          </InfoWithIcon>
          <InfoWithIcon>
            <Icon
              color={theme.text}
              size={'24px'}
              name={icons.HOUR_GLASS_OUTLINE}
              style={{ paddingRight: 8 }}
            />
            <Info key={`${user.uid}-memberSince`}>
              {formatDate(user.memberSince, 'yyyy') ?? '-'}
            </Info>
          </InfoWithIcon>
          <InfoWithIcon>
            <Icon
              color={theme.text}
              size={'24px'}
              name={icons.PERSON_CIRCLE_OUTLINE}
              style={{ paddingRight: 8 }}
            />
            <Info key={`${user.uid}-role`}>
              {memberRoleTranslation(user.role) ?? '-'}
            </Info>
          </InfoWithIcon>
          <Tag
            color={theme.text}
            text={`${capitalizeFirstLetterForEachWord(
              user.firstName,
            )} ${capitalizeFirstLetterForEachWord(user.lastName)}`}
            userUid={user.uid}
            style={{ alignSelf: 'end', marginTop: 24 }}
          />
        </MemberCard>
      ))}
    </Main>
  );
};

export default withTheme(MembersListMobileView);
