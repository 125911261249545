export const validateEmail = (email: string) => {
  return email.match(/^[\w\.-]+(\+\w+)?@([\w-]+\.)+[\w-]{2,4}$/);
};

export const validatePassword = (password: string) => {
  return password.match(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!^%*#?&+-,;:])[A-Za-z\d@$!^%*#?&+-,;:]{8,}$/g,
  );
};

export const validatePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return true;
  return phoneNumber
    .replaceAll(' ', '')
    .match(
      /^(?:(?:\+|00)33|0)[1-9](?:(?:(?:(?:\d{2}){4}\d{2})|\d{8})|(\d{2}\s){4}\d{2})$/g,
    );
};
