import { common, editor } from '../../../theme';
import { uploadBlogImage } from '../../firebase/blog';

const ColorPlugin = require('editorjs-text-color-plugin');
const Header = require('@editorjs/header');
const Code = require('@editorjs/inline-code');
const List = require('@editorjs/list');
const Underline = require('@editorjs/underline');
const Image = require('@editorjs/image');
const Table = require('@editorjs/table');

export const articleEditorToolsConfig = {
  header: Header,
  code: Code,
  table: Table,
  //TODO add attachment
  image: {
    class: Image,
    config: {
      buttonContent: 'Sélectionner une image',
      uploader: {
        uploadByFile(file: Blob) {
          return uploadBlogImage(file);
        },
      },
    },
  },
  list: {
    class: List,
    inlineToolbar: true,
    config: {
      defaultStyle: 'unordered',
    },
  },
  underline: Underline,
  Color: {
    class: ColorPlugin,
    config: {
      colorCollections: [
        common.danger,
        common.info,
        common.info2,
        common.success,
        editor.text,
      ],
      defaultColor: editor.text,
      type: 'text',
    },
  },
  Marker: {
    class: ColorPlugin,
    config: {
      colorCollections: [
        common.danger,
        common.info,
        common.info2,
        common.success,
      ],
      defaultColor: common.danger,
      type: 'marker',
    },
  },
};
