import { toast, ToastOptions } from 'react-toastify';
import { isMobile } from 'react-device-detect';

import { TOAST_LEVELS } from '../constants/toastLevels';

export type ToastLevels = 'error' | 'warning' | 'info' | 'success';

const toastConfig: ToastOptions = {
  pauseOnHover: true,
  theme: 'colored',
  position: isMobile ? 'bottom-center' : 'top-right',
};

export const displayToast = (
  level: ToastLevels,
  text: string,
  duration?: number,
): void => {
  switch (level) {
    case TOAST_LEVELS.SUCCESS:
      toast.success(text, {
        autoClose: duration ?? 6000,
        ...toastConfig,
      });
      break;
    case TOAST_LEVELS.ERROR:
      toast.error(text, {
        autoClose: duration ?? 6000,
        ...toastConfig,
      });
      break;
    case TOAST_LEVELS.WARNING:
      toast.warn(text, {
        autoClose: duration ?? 6000,
        ...toastConfig,
      });
      break;
    case TOAST_LEVELS.INFO:
      toast.info(text, {
        autoClose: duration ?? 6000,
        ...toastConfig,
      });
      break;
  }
};
