import { common, editor } from '../../../theme';

const ColorPlugin = require('editorjs-text-color-plugin');
const Code = require('@editorjs/inline-code');
const Underline = require('@editorjs/underline');
const List = require('@editorjs/list');

export const commentEditorToolsConfig = {
  code: Code,
  underline: Underline,
  list: {
    class: List,
    inlineToolbar: true,
    config: {
      defaultStyle: 'unordered',
    },
  },
  Color: {
    class: ColorPlugin,
    config: {
      colorCollections: [
        common.danger,
        common.info,
        common.info2,
        common.success,
        editor.text,
      ],
      defaultColor: editor.text,
      type: 'text',
    },
  },
  Marker: {
    class: ColorPlugin,
    config: {
      colorCollections: [
        common.danger,
        common.info,
        common.info2,
        common.success,
      ],
      defaultColor: common.danger,
      type: 'marker',
    },
  },
};
