import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../../theme';
import Loader from '../../../components/loader/Loader';
import {
  capitalizeFirstLetterForEachWord,
  displayPhoneNumberClearly,
} from '../../../utils/textDecoration';
import { formatDate } from '../../../utils/date';
import TextButton from '../../../components/textButton/TextButton';
import Paragraph from '../../../components/paragraph/Paragraph';
import { User, Users } from '../../../types/users';
import { ROLE } from '../../../constants/role';
import { icons } from '../../../constants/icons';
import Icon from '../../../components/icon/Icon';
import Tag from '../../../components/tag/Tag';

import {
  Info,
  Mail,
  MemberCard,
  MemberInfo,
  InfoWithIcon,
  MainList,
} from '../style';

type Props = {
  loading: boolean;
  onDisableUserModalOpen: (user: any) => void;
  theme: Theme;
  users: Users;
};

const AdminUsersListLaptop = (props: Props): ReactElement => {
  const { loading, onDisableUserModalOpen, theme, users } = props;

  if (loading) return <Loader size="48px" />;

  return (
    <MainList>
      {users
        .sort((a: User, b: User) =>
          a.lastName.localeCompare(b.lastName),
        )
        .map((user: User) => (
          <MemberCard key={`${user.uid}-row`}>
            <MemberInfo>
              <InfoWithIcon>
                <Icon
                  color={theme.text}
                  size={'24px'}
                  name={icons.MAIL_OUTLINE}
                />
                <Mail
                  color={theme.text}
                  href={`mailto:${user.email}`}
                  key={`${user.uid}-mail`}
                >
                  {user?.email ?? '-'}
                </Mail>
              </InfoWithIcon>
              <InfoWithIcon>
                <Icon
                  color={theme.text}
                  size={'24px'}
                  name={icons.CALL_OUTLINE}
                />
                <Info key={`${user.uid}-phoneNumber`}>
                  {(user &&
                    displayPhoneNumberClearly(user.phoneNumber)) ??
                    '-'}
                </Info>
              </InfoWithIcon>
              <InfoWithIcon>
                <Icon
                  color={theme.text}
                  size={'24px'}
                  name={icons.PHONE_OUTLINE}
                />
                <Info key={`${user.uid}-smartphoneNumber`}>
                  {(user &&
                    displayPhoneNumberClearly(
                      user.smartphoneNumber,
                    )) ??
                    '-'}
                </Info>
              </InfoWithIcon>
              <InfoWithIcon>
                <Icon
                  color={theme.text}
                  size={'24px'}
                  name={icons.HOUR_GLASS_OUTLINE}
                />
                <Info key={`${user.uid}-memberSince`}>
                  {(user && formatDate(user.memberSince, 'yyyy')) ??
                    '-'}
                </Info>
              </InfoWithIcon>
              <InfoWithIcon>
                <Icon
                  color={theme.text}
                  size={'24px'}
                  name={icons.PERSON_CIRCLE_OUTLINE}
                />
                <Info key={`${user.uid}-role`}>
                  {user.role ?? '-'}
                </Info>
              </InfoWithIcon>
              <InfoWithIcon>
                <Icon
                  color={user.disabled ? theme.danger : theme.success}
                  size={'24px'}
                  name={
                    user.disabled
                      ? icons.CLOSE_OUTLINE
                      : icons.CHECKMARK_OUTLINE
                  }
                />
                <Paragraph style={{ padding: 0, paddingRight: 8 }}>
                  {user.disabled
                    ? formatDate(user.disabled, 'dd/MM/yyyy HH:mm')
                    : 'Actif'}
                </Paragraph>
                <TextButton
                  color={user.disabled ? theme.success : theme.danger}
                  disabled={
                    user.role === ROLE.ADMIN ||
                    user.role === ROLE.OFFICE
                  }
                  onClick={() => onDisableUserModalOpen(user)}
                  title={user.disabled ? 'Réactiver' : 'Désactiver'} // TODO translate
                />
              </InfoWithIcon>
            </MemberInfo>
            <Tag
              color={theme.text}
              text={`${capitalizeFirstLetterForEachWord(
                user.firstName,
              )} ${capitalizeFirstLetterForEachWord(user.lastName)}`}
              userUid={user.uid}
              style={{ alignSelf: 'end', marginTop: 24 }}
            />
          </MemberCard>
        ))}
    </MainList>
  );
};

export default withTheme(AdminUsersListLaptop);
