import React, { ReactElement, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  auth,
  logInWithEmailAndPassword,
} from '../../../firebase/connection';
import {
  firstConnectionProcess,
  getUserInfo,
} from '../../../firebase/user';
import { goTo, routes } from '../../../router/routes';
import Input from '../../../components/input/Input';
import { withTheme } from 'styled-components';
import { Theme } from '../../../../theme';
import { icons } from '../../../constants/icons';
import TextIconButton from '../../../components/textIconButton/TextIconButton';
import CustomPageTitle from '../../../components/texts/CustomPageTitle';
import {
  handleFirebaseErrors,
  isEmailError,
} from '../../../utils/errors';
import { validateEmail } from '../../../utils/regexValidation';
import { displayToast, ToastLevels } from '../../../utils/Toast';
import { TOAST_LEVELS } from '../../../constants/toastLevels';
import { errorCodes } from '../../../types/errors';

import { Main } from './style';

type Props = { theme: Theme };

const LogIn = (props: Props): ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [user] = useAuthState(auth);

  const goToLogin = () => goTo(location, history, routes.LOGIN);
  const goToRestPwd = () => goTo(location, history, routes.RESET_PWD);
  const goToMenu = () => goTo(location, history, routes.MENU.MAIN);
  const goToUserFinalization = () =>
    goTo(location, history, routes.USER_FINALIZATION);

  const [isPasswordDisplayed, setIsPasswordDisplayed] =
    useState(false);

  const [inputFields, setInputFields] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    const handleUserAuth = async () => {
      if (user) {
        try {
          await firstConnectionProcess(user);
          const userInfo = await getUserInfo(user.uid);

          if (!userInfo.isFirstConnection) {
            if (!location.pathname.includes('menu')) {
              goToUserFinalization();
            }
          } else {
            if (!location.pathname.includes('menu')) {
              goToMenu();
            }
          }
        } catch (error) {
          console.log(error);
          goToLogin();
        }
      }
    };

    handleUserAuth();
  }, [user, location]);

  const handleChange = (e: any) => {
    setErrors({
      ...errors,
      [e.target.name]: '',
    });
    setInputFields({
      ...inputFields,
      [e.target.name]: e.target.value,
    });
  };

  const validateValues = (inputValues: {
    email: string;
    password: string;
  }) => {
    let err = {
      email: '',
      password: '',
    };

    if (!inputValues.password) {
      handleFirebaseErrors(
        errorCodes.EmptyPassword,
        (x) => (err.password = x),
      );
    }

    if (!inputValues.email) {
      handleFirebaseErrors(
        errorCodes.EmptyEmail,
        (x) => (err.email = x),
      );
    } else if (!validateEmail(inputValues.email)) {
      handleFirebaseErrors(
        errorCodes.InvalidEmail,
        (x) => (err.email = x),
      );
    }
    setErrors(err);

    return err;
  };

  const onSubmit = async () => {
    try {
      if (
        Object.values(validateValues(inputFields)).every(
          (err) => err === null || err === '',
        )
      ) {
        await logInWithEmailAndPassword(
          inputFields.email,
          inputFields.password,
        );
        displayToast(
          TOAST_LEVELS.SUCCESS as ToastLevels,
          t('CONNECTION.CONNECTION_OK'),
        );
      }
    } catch (error: any) {
      handleFirebaseErrors(
        error.code || error.message,
        isEmailError(error.code || error.message)
          ? (x) => setErrors({ ...errors, email: x })
          : (x) => setErrors({ ...errors, password: x }),
      );
    }
  };

  return (
    <Main id={location.pathname}>
      <CustomPageTitle
        color={props.theme.text}
        style={{ paddingBottom: 40 }}
      >
        {t('COMMON.LOGIN')}
      </CustomPageTitle>
      <Input
        autoFocus
        color={props.theme.text}
        error={errors.email}
        leftIconName={icons.MAIL_OUTLINE}
        onChange={handleChange}
        placeholder={t('COMMON.EMAIL')}
        title={t('COMMON.EMAIL')}
        type="email"
        value={inputFields.email}
        name="email"
      />
      <Input
        color={props.theme.text}
        error={errors.password}
        leftIconName={icons.LOCK_CLOSED_OUTLINE}
        onChange={handleChange}
        onRightIconClick={() =>
          setIsPasswordDisplayed(!isPasswordDisplayed)
        }
        placeholder={t('COMMON.PASSWORD')}
        rightIconName={
          isPasswordDisplayed
            ? icons.EYE_OUTLINE
            : icons.EYE_OFF_OUTLINE
        }
        title={t('COMMON.PASSWORD')}
        type={isPasswordDisplayed ? 'text' : 'password'}
        value={inputFields.password}
        style={{ marginBottom: 40 }}
        name="password"
      />
      <TextIconButton
        color={props.theme.text}
        iconName={icons.PERSON_CIRCLE_OUTLINE}
        onClick={() => onSubmit()}
        title={t('COMMON.VALIDATE')}
        style={{ marginBottom: 20 }}
      />
      <TextIconButton
        color={props.theme.text}
        iconName={icons.REFRESH_OUTLINE}
        onClick={() => goToRestPwd()}
        title={t('CONNECTION.RESET_PASSWORD')}
      />
    </Main>
  );
};

export default withTheme(LogIn);
