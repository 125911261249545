import React, { useState, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';

import { Language } from '../../../i18n';
import { Theme } from '../../../theme';
import { icons } from '../../constants/icons';
import TextIconButton from '../textIconButton/TextIconButton';

type Props = {
  theme: Theme;
};

const Lang = (props: Props): ReactElement => {
  const { theme } = props;
  const { i18n } = useTranslation();
  const [lang, setLang] = useState<Language>(
    i18n.language as Language,
  );

  const changeLanguage = (lang: Language): void => {
    switch (lang) {
      case Language.EN:
        setLang(Language.EN);
        i18n.changeLanguage(Language.EN);
        break;
      case Language.FR:
      default:
        setLang(Language.FR);
        i18n.changeLanguage(Language.FR);
        break;
    }
  };

  return (
    <TextIconButton
      color={theme.text}
      iconName={icons.LANGUAGE_OUTLINE}
      onClick={() =>
        changeLanguage(
          lang === Language.FR ? Language.EN : Language.FR,
        )
      }
      title={lang === Language.FR ? 'Français' : 'English'}
    />
  );
};

export default withTheme(Lang);
