export enum errorCodes {
  WrongPassword = 'auth/wrong-password',
  UserNotFound = 'auth/user-not-found',
  UserDisabled = 'auth/user-disabled',
  TooManyRequests = 'auth/too-many-requests',
  NewMemberEmailAlreadyInUse = 'auth/email-already-in-use',
  EventNotFound = 'event/not-found',
  EventCancelInfoRequired = 'event/cancel-info-required',
  InvalidUser = 'user/invalid',
  EmptyPhoneNumber = 'phoneNumber/empty',
  InvalidPhoneNumber = 'phoneNumber/invalid',
  InvalidEmail = 'email/invalid',
  SimilarEmail = 'email/similar-with-previous',
  PermissionDenied = 'user/permission-denied',
  EmptyEmail = 'email/empty',
  EmptyPassword = 'password/empty',
  EmptyFirstName = 'firstName/empty',
  EmptyLastName = 'lastName/empty',
  InvalidPassword = 'password/invalid',
  SimilarPassword = 'password/similar-with-previous',
  DifferentValidationPassword = 'password/validation-password-is-different',
  AvatarFileOversize = 'avatar/file-oversize',
  AvatarFileRequired = 'avatar/file-required',
  AvatarFileUploadFail = 'avatar/file-upload-fail',
  NotFoundDeleteArticleBlog = 'not-found-delete-article-blog', //TODO better message
  NotFoundUpdateArticleBlog = 'not-found-update-article-blog', //TODO better message
  BlogFileOversize = 'blog file oversize', //TODO better message
}
