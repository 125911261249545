import React, { AnchorHTMLAttributes } from 'react';
import { Legend as T } from './style';

type Props = {
  children: string;
  color: string;
  style?: React.CSSProperties;
};

const Legend = (
  props: Props & AnchorHTMLAttributes<HTMLAnchorElement>,
): JSX.Element => {
  const { children, color, style, ...propsRest } = props;

  return (
    <T
      color={color}
      displayPointer={!!propsRest.href || !!propsRest.onClick}
      style={style}
      {...propsRest}
    >
      {children}
    </T>
  );
};

export default Legend;
