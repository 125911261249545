import React, { ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../../theme';
import Input from '../../../components/input/Input';
import TextIconButton from '../../../components/textIconButton/TextIconButton';
import { sendPasswordReset } from '../../../firebase/connection';
import { icons } from '../../../constants/icons';
import CustomPageTitle from '../../../components/texts/CustomPageTitle';
import { handleFirebaseErrors } from '../../../utils/errors';
import { displayToast, ToastLevels } from '../../../utils/Toast';
import { TOAST_LEVELS } from '../../../constants/toastLevels';
import { validateEmail } from '../../../utils/regexValidation';
import { errorCodes } from '../../../types/errors';

import { Main } from './style';

type Props = { theme: Theme };

const ResetPassword = (props: Props): ReactElement => {
  const location = useLocation();
  const { t } = useTranslation();

  const [inputFields, setInputFields] = useState({
    email: '',
  });
  const [errors, setErrors] = useState({
    email: '',
  });

  const handleChange = (e: any) => {
    setErrors({
      ...errors,
      [e.target.name]: '',
    });
    setInputFields({
      ...inputFields,
      [e.target.name]: e.target.value,
    });
  };

  const validateValues = (inputValues: { email: string }) => {
    let err = {
      email: '',
    };

    if (!inputValues.email) {
      handleFirebaseErrors(
        errorCodes.EmptyEmail,
        (x) => (err.email = x),
      );
    } else if (!validateEmail(inputValues.email)) {
      handleFirebaseErrors(
        errorCodes.InvalidEmail,
        (x) => (err.email = x),
      );
    }
    setErrors(err);

    return err;
  };

  const resetPassword = async () => {
    try {
      if (
        Object.values(validateValues(inputFields)).every(
          (err) => err === null || err === '',
        )
      ) {
        await sendPasswordReset(inputFields.email);
        displayToast(
          TOAST_LEVELS.SUCCESS as ToastLevels,
          t('CONNECTION.MAIL_SEND_OK'),
        );
      }
    } catch (error: any) {
      handleFirebaseErrors(error.code || error.message, (x) =>
        setErrors({ ...errors, email: x }),
      );
    }
  };

  return (
    <Main id={location.pathname}>
      <CustomPageTitle
        color={props.theme.text}
        style={{ paddingBottom: 40 }}
      >
        {t('CONNECTION.RESET_PASSWORD')}
      </CustomPageTitle>
      <Input
        autoFocus
        color={props.theme.text}
        error={errors.email}
        leftIconName={icons.MAIL_OUTLINE}
        onChange={handleChange}
        placeholder={t('COMMON.EMAIL')}
        title={t('COMMON.EMAIL')}
        style={{ marginBottom: 40 }}
        type="email"
        value={inputFields.email}
        name="email"
      />
      <TextIconButton
        color={props.theme.text}
        iconName={icons.MAIL_OUTLINE}
        onClick={() => resetPassword()}
        title={t('CONNECTION.SEND_REST_MAIL')}
      />
    </Main>
  );
};

export default withTheme(ResetPassword);
