export const firebaseConfig = {
  apiKey: 'AIzaSyB7XDLrW2MgJ2XkMbQ-zuinwoL6vV1a2Ck',
  authDomain: 'rcm-app-fc174.firebaseapp.com',
  projectId: 'rcm-app-fc174',
  storageBucket: 'rcm-app-fc174.appspot.com',
  messagingSenderId: '942548070532',
  appId: '1:942548070532:web:b312eeef6795024d4892d1',
  measurementId: 'G-2573EDGBVS',
};

export const firebaseDevConfig = {
  apiKey: 'AIzaSyD8PeGixLnqe7LrBM6ByPCv6GM6fXDK9qw',
  authDomain: 'rcm-app-dev-a8ce4.firebaseapp.com',
  projectId: 'rcm-app-dev-a8ce4',
  storageBucket: 'rcm-app-dev-a8ce4.appspot.com',
  messagingSenderId: '93539218807',
  appId: '1:93539218807:web:8f64edc07e6309f7529d72',
  measurementId: 'G-Y9DM66JC5Y',
};

export const firebaseURL =
  'https://console.firebase.google.com/u/0/project/rcm-app-fc174/authentication/users';
export const firebaseURLDev =
  'https://console.firebase.google.com/u/0/project/rcm-app-dev-a8ce4/authentication/users';
