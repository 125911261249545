import styled from 'styled-components';

export const Main = styled.div<{
  color: string;
}>`
  align-items: center;
  border-radius: 48px;
  border: 2px solid;
  border-color: ${({ color }) => color};
  display: flex;
  flex-direction: row;
  height: 36px;
  padding: 0px 8px 0px 6px;
  width: fit-content;

  &:disabled {
    opacity: 0.5;
  }
`;

export const TagText = styled.p`
  color: ${(props) => props.color};
  display: block;
  margin: 0;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
