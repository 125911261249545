import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const Main = styled.label<{ disabled?: boolean }>`
  align-items: center;
  background-color: transparent;
  border: ${(props) => `2px solid ${props.color}`};
  border-radius: 32px;
  display: flex;
  justify-content: space-around;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  padding: 2px 16px;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  text-transform: uppercase;
  float: left;

  &:hover {
    cursor: pointer;
    box-shadow: ${(props) =>
      `0px ${isMobile ? '0px' : '4px'} ${props.color}`};
    transition: 200ms;
  }

  &:active {
    position: relative;
    top: 2px;
  }
`;
