import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';

import { Theme } from '../../../theme';
import { memberRoleTranslation } from '../../constants/role';
import { displayPhoneNumberClearly } from '../../utils/textDecoration';
import { formatDate } from '../../utils/date';
import { Users } from '../../types/users';
import Icon from '../../components/icon/Icon';
import { icons } from '../../constants/icons';
import AvatarWithText from '../../components/avatarWithText/AvatarWithText';

import {
  Info,
  InfoWithIcon,
  Mail,
  Main,
  MemberCard,
  MemberInfo,
} from './style';

type Props = { users: Users; theme: Theme };

const MembersListMobileView = (props: Props): ReactElement => {
  const { users, theme } = props;

  return (
    <Main>
      {users.map((user) => (
        <MemberCard key={`${user.uid}-row`}>
          <AvatarWithText
            size={'big'}
            userUid={user.uid}
            fullUserName={`${user.firstName} ${user.lastName}`}
            style={{ justifyContent: 'flex-start' }}
          />
          <MemberInfo>
            <InfoWithIcon>
              <Icon
                color={theme.text}
                size={'24px'}
                name={icons.MAIL_OUTLINE}
              />
              <Mail
                color={theme.text}
                href={`mailto:${user.email}`}
                key={`${user.uid}-mail`}
              >
                {user?.email ?? '-'}
              </Mail>
            </InfoWithIcon>
            <InfoWithIcon>
              <Icon
                color={theme.text}
                size={'24px'}
                name={icons.CALL_OUTLINE}
              />
              <Info key={`${user.uid}-phoneNumber`}>
                {(user &&
                  displayPhoneNumberClearly(user.phoneNumber)) ??
                  '-'}
              </Info>
            </InfoWithIcon>
            <InfoWithIcon>
              <Icon
                color={theme.text}
                size={'24px'}
                name={icons.PHONE_OUTLINE}
              />
              <Info key={`${user.uid}-smartphoneNumber`}>
                {(user &&
                  displayPhoneNumberClearly(user.smartphoneNumber)) ??
                  '-'}
              </Info>
            </InfoWithIcon>
            <InfoWithIcon>
              <Icon
                color={theme.text}
                size={'24px'}
                name={icons.HOUR_GLASS_OUTLINE}
              />
              <Info key={`${user.uid}-memberSince`}>
                {(user && formatDate(user.memberSince, 'yyyy')) ??
                  '-'}
              </Info>
            </InfoWithIcon>
            <InfoWithIcon>
              <Icon
                color={theme.text}
                size={'24px'}
                name={icons.PERSON_CIRCLE_OUTLINE}
              />
              <Info key={`${user.uid}-role`}>
                {(user && memberRoleTranslation(user.role)) ?? '-'}
              </Info>
            </InfoWithIcon>
          </MemberInfo>
        </MemberCard>
      ))}
    </Main>
  );
};

export default withTheme(MembersListMobileView);
