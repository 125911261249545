import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';
import { Theme } from '../../../theme';

import Icon from '../icon/Icon';
import Loader from '../loader/Loader';

import {
  Container,
  ErrorMassage,
  SelectCore,
  Main,
  InputHeaderText,
  InputHeader,
} from './style';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  color: string;
  disabled?: boolean;
  error?: string;
  iconColor?: string;
  iconStyle?: React.CSSProperties;
  id: string;
  leftIconName?: string;
  loading?: boolean;
  name: string;
  onChange?: (e: any) => void;
  required?: boolean;
  title?: string;
  style?: React.CSSProperties;
  value: string | number;
  theme: Theme;
};

const MultiSelect = (
  props: Props & React.SelectHTMLAttributes<HTMLSelectElement>,
): ReactElement => {
  const {
    children,
    color,
    disabled,
    error,
    iconColor,
    iconStyle,
    id,
    leftIconName,
    loading,
    name,
    onChange,
    required,
    title,
    style,
    value,
    theme,
    ...propsRest
  } = props;

  return (
    <Main disabled={disabled} id={id} style={style}>
      {title ? (
        <InputHeader>
          {
            <InputHeaderText
              color={color}
              style={{ paddingBottom: 8 }}
            >
              {`${title} ${required ? '*' : ''}`}
            </InputHeaderText>
          }
        </InputHeader>
      ) : null}
      <Container color={color}>
        {leftIconName && !loading && (
          <Icon
            name={leftIconName}
            size={'24px'}
            color={iconColor ?? color}
            style={iconStyle}
          />
        )}
        {loading && <Loader size={'24px'} />}
        <SelectCore
          color={color}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          required={required}
          value={value}
          {...propsRest}
        >
          {children}
        </SelectCore>
      </Container>
      {error && (
        <ErrorMassage color={theme.danger}>{error}</ErrorMassage>
      )}
    </Main>
  );
};

export default withTheme(MultiSelect);
