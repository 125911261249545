import { ReactElement, useState } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../theme';
import { icons } from '../../constants/icons';
import EditionModal from '../../components/editionModal/EditionModal';
import { togleUserDesactivation } from '../../firebase/user';
import { User } from '../../types/users';
import { getFirebaseURLForEnv } from '../../utils/changeConfigWithEnv';
import Link from '../../components/texts/Link';
import { displayToast, ToastLevels } from '../../utils/Toast';
import { TOAST_LEVELS } from '../../constants/toastLevels';

type Props = {
  closeDisableUserModal: () => void;
  isDisableUserModalOpen: boolean;
  currentUser: User;
  theme: Theme;
};

const DisableUserModal = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const {
    closeDisableUserModal,
    isDisableUserModalOpen,
    currentUser,
    theme,
  } = props;

  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    try {
      await togleUserDesactivation(
        currentUser.uid,
        !currentUser.disabled,
      );
      displayToast(
        TOAST_LEVELS.SUCCESS as ToastLevels,
        t(
          currentUser.disabled
            ? 'ADMIN.ACTIVATE_USER_SUCCESS'
            : 'ADMIN.DEACTIVATE_USER_SUCCESS',
        ),
      );
      closeDisableUserModal();
    } catch (error) {
      displayToast(
        //TODO handleError func
        TOAST_LEVELS.ERROR as ToastLevels,
        t(
          currentUser.disabled
            ? 'ADMIN.ACTIVATE_USER_FAIL'
            : 'ADMIN.DEACTIVATE_USER_FAIL',
        ),
      );
    } finally {
      setLoading(false);
    }
  };

  const firebaseUrl = (
    <Link color={theme.text} url={getFirebaseURLForEnv()}>
      Firebase
    </Link>
  );

  return (
    <EditionModal
      button={{
        color: theme.success,
        iconName: icons.CHECKMARK_OUTLINE,
        text: t('COMMON.CONFIRM'),
        action: onSubmit,
      }}
      closeAction={closeDisableUserModal}
      ismodalOpen={isDisableUserModalOpen}
      loading={loading}
      secondaryButton={{
        color: theme.danger,
        iconName: icons.CLOSE_OUTLINE,
        text: t('COMMON.CANCEL'),
        action: closeDisableUserModal,
      }}
      title={
        currentUser.disabled
          ? t('ADMIN.ACTIVATE_USER')
          : t('ADMIN.DEACTIVATE_USER')
      }
    >
      <p>
        {currentUser.disabled
          ? t('ADMIN.ACTIVATE_USER_MODAL_TEXT')
          : t('ADMIN.DEACTIVATE_USER_MODAL_TEXT')}
        <br />
        <br />
        {firebaseUrl}
      </p>
    </EditionModal>
  );
};

export default withTheme(DisableUserModal);
