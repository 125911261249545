import styled from 'styled-components';

import { device, size } from '../../constants/screenSizes';

export const Main = styled.header`
  align-items: center;
  background-color: ${(props) => props.theme.background};
  display: flex;
  flex-shrink: 0 !important;
  font-size: calc(8px + 2vmin);
  height: 5rem;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1100;
  overflow: hidden;
  border-bottom: ${(props) =>
    `1px solid ${props.theme.invertedBackground}`};
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  width: 100%;

  @media ${device.laptop} {
    max-width: ${() => size.laptop}px;
  }
`;

export const HeaderRight = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
