import React, { useEffect, useRef } from 'react';

import EditorJS from '@editorjs/editorjs';
import { Theme } from '../../../theme';
import { articleEditorToolsConfig } from './ArticleEditorToolsConfig';

type Props = { data: any; holder: string };

const Reader = (props: Props) => {
  const { data, holder } = props;
  const readerInstance = useRef<EditorJS>();

  useEffect(() => {
    if (!readerInstance.current) {
      const editor = new EditorJS({
        holder: holder,
        minHeight: 54,
        readOnly: true,
        tools: articleEditorToolsConfig,
        data: data,
      });
      readerInstance.current = editor;
    }

    return () => {
      if (readerInstance.current?.destroy) {
        readerInstance.current.destroy();
      }
    };
  }, []);

  return <div id={holder} key={holder} />;
};

export default Reader;
