export const generateTempPassword = (): string => {
  const uppercaseLetters = 'ABCDEFGHIJKLMNPQRSTUVWXYZ';
  const lowercaseLetters = 'abcdefghijklmnpqrstuvwxyz';
  const numbers = '123456789';
  const symbols = '!@#$%^&*()-_+=<>?';

  let password = '';

  for (let i = 0; i < 2; i++) {
    password += uppercaseLetters.charAt(
      Math.floor(Math.random() * uppercaseLetters.length),
    );
  }

  for (let i = 0; i < 2; i++) {
    password += lowercaseLetters.charAt(
      Math.floor(Math.random() * lowercaseLetters.length),
    );
  }

  for (let i = 0; i < 2; i++) {
    password += numbers.charAt(
      Math.floor(Math.random() * numbers.length),
    );
  }

  for (let i = 0; i < 2; i++) {
    password += symbols.charAt(
      Math.floor(Math.random() * symbols.length),
    );
  }

  password = password
    .split('')
    .sort(() => Math.random() - 0.5)
    .join('');

  return password;
};
