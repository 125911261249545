import React, { ReactElement, useEffect, useState } from 'react';
import { useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Theme } from '../../../theme';

import MenuButton from '../../components/menuButton/MenuButton';
import { auth, logout } from '../../firebase/connection';
import { icons } from '../../constants/icons';
import { goTo, routes } from '../../router/routes';

import { Grid, Main } from './style';
import { ENV } from '../../constants/env';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getUserRole } from '../../firebase/user';
import { ROLE } from '../../constants/role';
import { handleFirebaseErrors } from '../../utils/errors';

type Props = { theme: Theme };

const Menu = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const { theme } = props;
  const { isPortrait } = useMobileOrientation();

  const history = useHistory();
  const location = useLocation();

  const [user] = useAuthState(auth);
  const [role, setRole] = useState('member');

  const goToPlanning = () =>
    goTo(location, history, routes.MENU.PLANNING.MAIN);
  const goToHome = () => goTo(location, history, routes.HOME);
  const goToSettings = () =>
    goTo(location, history, routes.MENU.SETTINGS);
  const goToAdminSettings = () =>
    goTo(location, history, routes.MENU.ADMIN_SETTINGS);
  const goToMembersList = () =>
    goTo(location, history, routes.MENU.MEMBERS);
  const goToBlog = () => goTo(location, history, routes.BLOG.MAIN);
  const goToHomeWithLogOut = () => {
    try {
      logout();
      goTo(location, history, routes.HOME, true);
    } catch (error: any) {
      handleFirebaseErrors(error);
    }
  };

  useEffect(() => {
    try {
      if (user)
        getUserRole(user.uid).then((r) => {
          setRole(r);
        });
    } catch (e) {}
  }, []);

  return (
    <Main>
      <Grid isPortrait={isPortrait}>
        <MenuButton
          iconColor={theme.success}
          iconName={icons.CALENDAR_OUTLINE}
          onClick={goToPlanning}
          subTitle={t('MENU.PLANNING_DESCRIPTION')}
          title={t('MENU.PLANNING')}
        />
        <MenuButton
          iconColor={theme.success}
          iconName={icons.PEOPLE_OUTLINE}
          title={t('MENU.MEMBER_LIST')}
          onClick={goToMembersList}
        />
        {process.env.NODE_ENV === ENV.DEV && (
          <>
            <MenuButton
              iconColor={theme.info2}
              iconName={icons.IMAGE_OUTLINE}
              onClick={goToBlog}
              subTitle={t('MENU.BLOG_DESCRIPTION')}
              title={t('MENU.BLOG')}
            />
            <MenuButton
              disabled={true}
              iconColor={theme.warning}
              iconName={icons.CHATBOX_ELLIPSES_OUTLINE}
              onClick={() => null}
              subTitle={t('MENU.FORUM_DESCRIPTION')}
              title={t('MENU.FORUM')}
            />
          </>
        )}
        <MenuButton
          iconColor={theme.text}
          iconName={icons.SETTINGS_OUTLINE}
          onClick={goToSettings}
          subTitle={t('MENU.SETTINGS_DESCRIPTION')}
          title={t('MENU.SETTINGS')}
        />
        {role === ROLE.ADMIN ? (
          <MenuButton
            iconColor={theme.text}
            iconName={icons.BUILD_OUTLINE}
            onClick={goToAdminSettings}
            title={t('Admin Settings')}
          />
        ) : null}
        <MenuButton
          iconColor={theme.text}
          iconName={icons.TRAIN_OUTLINE}
          subTitle={t('MENU.VISITOR_PAGE_DESCRIPTION')}
          title={t('MENU.VISITOR_PAGE')}
          onClick={goToHome}
        />
        <MenuButton
          iconColor={theme.danger}
          iconName={icons.LOG_OUT_OUTLINE}
          onClick={goToHomeWithLogOut}
          title={t('COMMON.LOGOUT')}
        />
      </Grid>
    </Main>
  );
};

export default withTheme(Menu);
