import React, { ReactElement, useState } from 'react';
import Modal from 'react-modal';
import { withTheme } from 'styled-components';

import { Theme } from '../../../theme';
import Legend from '../texts/CustomLegend';
import { useWindowDimensions } from '../../utils/useWindowDimensions';
import { icons } from '../../constants/icons';
import IconButton from '../iconButton/IconButton';

import {
  LegendContainer,
  ModalImg,
  WrapperContainer,
  WrapperImg,
} from './style';

type CustomImage = {
  path: string;
  legend?: string;
};
type Props = { imgs: CustomImage[]; theme: Theme };

const ImagesWrapper = (props: Props): ReactElement => {
  const { imgs, theme } = props;

  const [ismodalOpen, setIsmodalOpen] = useState('');

  const { height, width } = useWindowDimensions();

  const openImg = (img: string) => {
    setIsmodalOpen(img);
  };

  const closeImg = () => {
    setIsmodalOpen('');
  };

  const isPortrait = () => {
    return height > width;
  };

  return (
    <>
      <WrapperContainer>
        {imgs.map((img) => (
          <LegendContainer key={`container ${img.path}`}>
            <WrapperImg
              alt={img.path}
              maxHeight={isPortrait() ? undefined : 304}
              key={img.path}
              loading="lazy"
              onClick={() => openImg(img.path)}
              src={img.path}
              width={isPortrait() ? '90%' : 'auto'}
            />
            {img.legend ? (
              <Legend color={theme.text} style={{ paddingTop: 8 }}>
                {img.legend}
              </Legend>
            ) : (
              <div style={{ height: 25, width: 1 }} />
            )}
          </LegendContainer>
        ))}
      </WrapperContainer>
      <Modal
        contentLabel={`Opened Image ${ismodalOpen}`}
        isOpen={!!ismodalOpen}
        onRequestClose={closeImg}
        style={{
          content: {
            position: 'initial',
            background: 'tranparent',
            border: 'none',
            borderRadius: 16,
            padding: 0,
            margin: 24,
            zIndex: 1200,
            display: 'flex',
            width: 'fit-content',
            backgroundColor: theme.classicButton,
          },
          overlay: {
            backgroundColor: `${theme.background}E6`,
            zIndex: 1200,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <div
          style={{
            backgroundColor: `${theme.invertedText}E6`,
            position: 'absolute',
            padding: 8,
            borderTopLeftRadius: 16,
            borderBottomRightRadius: 16,
          }}
        >
          <IconButton
            color={theme.text}
            onClick={closeImg}
            iconName={icons.CLOSE_OUTLINE}
            isbordered
          />
        </div>
        <ModalImg
          alt={ismodalOpen}
          height={'auto'}
          key={ismodalOpen}
          src={ismodalOpen}
          width={'100%'}
        />
      </Modal>
    </>
  );
};

export default withTheme(ImagesWrapper);
