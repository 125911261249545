import React from 'react';

import Legend from './CustomLegend';

type Props = {
  children: string;
  color: string;
  style?: React.CSSProperties;
  url: string;
};

const Link = (
  props: Props & React.HTMLAttributes<HTMLHeadingElement>,
): JSX.Element => {
  const { children, color, style, url } = props;

  return (
    <Legend
      color={color}
      href={url}
      rel="noreferrer"
      target="_blank"
      style={style}
    >
      {children}
    </Legend>
  );
};

export default Link;
