import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const Main = styled.button`
  align-items: center;
  background-color: transparent;
  border: ${(props) => `2px solid ${props.color}`};
  border-radius: 32px;
  display: flex;
  justify-content: space-around;
  text-transform: uppercase;
  padding: 2px 16px;
  position: relative;

  &:hover:enabled {
    cursor: pointer;
    box-shadow: ${(props) =>
      `0px ${isMobile ? '0px' : '4px'} ${props.color}`};
    transition: 200ms;
  }

  &:active:enabled {
    position: relative;
    top: 2px;
  }

  &:disabled {
    opacity: 0.5;
  }
`;
