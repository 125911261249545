import {
  firebaseConfig,
  firebaseDevConfig,
  firebaseURL,
  firebaseURLDev,
} from '../../firebaseConfig';
import { ENV } from '../constants/env';

export const getDatabaseKeyForEnv = () =>
  process.env.NODE_ENV === ENV.PROD
    ? firebaseConfig
    : firebaseDevConfig;

export const getFirebaseURLForEnv = () =>
  process.env.NODE_ENV === ENV.PROD ? firebaseURL : firebaseURLDev;
