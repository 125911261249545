import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { withTheme } from 'styled-components';

import { Theme } from '../../../theme';
import { ENV } from '../../constants/env';
import { icons } from '../../constants/icons';
import { LINKS } from '../../constants/links';
import { size } from '../../constants/screenSizes';
import { goTo, routes } from '../../router/routes';
import { openLinkNewTab } from '../../utils/openLinks';
import { useWindowDimensions } from '../../utils/useWindowDimensions';
import IconButton from '../iconButton/IconButton';
import TextIconButton from '../textIconButton/TextIconButton';
import Legend from '../texts/CustomLegend';
import Link from '../texts/Link';

import { FooterText, Grid, GridContainer, Main } from './style';

type Props = {
  theme: Theme;
};

const Footer = (props: Props): ReactElement => {
  const { theme } = props;

  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const history = useHistory();
  const location = useLocation();

  const goToVersion = () => goTo(location, history, routes.VERSIONS);

  const Vincent = (
    <Link color={theme.text} url={LINKS.ODEXVY}>
      Vincent
    </Link>
  );
  const Mathis = (
    <Link color={theme.text} url={LINKS.FLUX6663}>
      Mathis
    </Link>
  );
  const version = (
    <Legend
      color={theme.text}
      onClick={() => goToVersion()}
      style={{ color: theme.text, padding: 0 }}
    >
      {`version ${process.env.REACT_APP_VERSION}`}
    </Legend>
  );

  return (
    <Main>
      <Grid>
        <GridContainer>
          <FooterText>
            {t('FOOTER.LEFT', { date: new Date().getFullYear() })}
          </FooterText>
        </GridContainer>
        <GridContainer>
          {width > size.laptop ? (
            <>
              <TextIconButton
                color={theme.brandLogo.insta}
                iconName={icons.INSTAGRAM}
                onClick={(e: any) => {
                  openLinkNewTab(e, LINKS.INSTA);
                }}
                title="Instagram"
              />
              {process.env.NODE_ENV === ENV.DEV ? (
                <TextIconButton
                  color={theme.brandLogo.youtube}
                  iconName={icons.YOUTUBE}
                  onClick={(e: any) => null}
                  title="Youtube"
                />
              ) : null}
              <TextIconButton
                color={theme.brandLogo.facebook}
                iconName={icons.FACEBOOK}
                onClick={(e: any) => {
                  openLinkNewTab(e, LINKS.FACEBOOK);
                }}
                title="Facebook"
              />
            </>
          ) : (
            <>
              <IconButton
                isbordered
                color={theme.brandLogo.insta}
                iconName={icons.INSTAGRAM}
                onClick={(e: any) => {
                  openLinkNewTab(e, LINKS.INSTA);
                }}
              />
              {process.env.NODE_ENV === ENV.DEV ? (
                <IconButton
                  isbordered
                  color={theme.brandLogo.youtube}
                  iconName={icons.YOUTUBE}
                  onClick={(e: any) => null}
                />
              ) : null}
              <IconButton
                isbordered
                color={theme.brandLogo.facebook}
                iconName={icons.FACEBOOK}
                onClick={(e: any) => {
                  openLinkNewTab(e, LINKS.FACEBOOK);
                }}
              />
            </>
          )}
        </GridContainer>
        <GridContainer
          style={{
            flexDirection: 'column',
          }}
        >
          <FooterText
            style={{
              textAlign: 'end',
            }}
          >
            {t('FOOTER.RIGHT1')}
            {Vincent} / {Mathis}
          </FooterText>
          {width > size.tablet ? version : null}
        </GridContainer>
      </Grid>
    </Main>
  );
};

export default withTheme(Footer);
