import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';
import Modal from 'react-modal';

import { Theme } from '../../../theme';

import { Main, AlertText, Footer } from './style';
import CustomTitle from '../texts/CustomTitle';
import TextIconButton from '../textIconButton/TextIconButton';
import { isMobile } from 'react-device-detect';

type Button = {
  color: string;
  iconName: string;
  text: string;
  action: () => void;
};

type Props = {
  button: Button;
  closeAction: () => void;
  ismodalOpen: boolean;
  message?: string;
  secondaryButton?: Button;
  style?: React.CSSProperties;
  title: string;
  theme: Theme;
};

const CUSTOM_STYLE = {
  content: {
    borderRadius: 16,
    bottom: 'auto',
    left: '50%',
    marginRight: '-50%',
    maxWidth: '80%',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

const Alert = (props: Props): ReactElement => {
  const {
    button,
    closeAction,
    ismodalOpen,
    message,
    secondaryButton,
    style,
    title,
    theme,
  } = props;

  return (
    <Main id="Alert" style={style}>
      <Modal
        contentLabel="Confirmation Modal"
        ariaHideApp={false}
        isOpen={ismodalOpen}
        onRequestClose={closeAction}
        style={{
          content: {
            ...CUSTOM_STYLE.content,
            backgroundColor: theme.background,
            zIndex: 1200,
          },
          overlay: {
            backgroundColor: `${theme.background}E6`,
            zIndex: 1200,
          },
        }}
      >
        <CustomTitle color={theme.text}>{title}</CustomTitle>
        <AlertText>{message}</AlertText>
        <Footer>
          {secondaryButton ? (
            <TextIconButton
              color={secondaryButton.color}
              iconName={secondaryButton.iconName}
              onClick={() => secondaryButton.action()}
              title={secondaryButton.text}
            />
          ) : null}
          <TextIconButton
            color={button.color}
            iconName={button.iconName}
            onClick={() => button.action()}
            style={{
              margin: !isMobile ? '0 0 0 24px' : '24px 0 0 0',
            }}
            title={button.text}
          />
        </Footer>
      </Modal>
    </Main>
  );
};

export default withTheme(Alert);
