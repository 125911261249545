import React from 'react';
import { Paragraph as P } from './style';

type Props = {
  children: string;
  style?: React.CSSProperties;
};

const Paragraph = (
  props: Props & React.HTMLAttributes<HTMLParagraphElement>,
): JSX.Element => {
  const { children, style } = props;

  return <P style={style}>{children}</P>;
};

export default Paragraph;
