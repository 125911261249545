import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../../theme';
import Loader from '../../../components/loader/Loader';
import { displayPhoneNumberClearly } from '../../../utils/textDecoration';
import { formatDate } from '../../../utils/date';
import TextButton from '../../../components/textButton/TextButton';
import Paragraph from '../../../components/paragraph/Paragraph';
import { ROLE } from '../../../constants/role';
import { icons } from '../../../constants/icons';
import Icon from '../../../components/icon/Icon';
import AvatarWithText from '../../../components/avatarWithText/AvatarWithText';

import {
  Info,
  Mail,
  MemberCard,
  MemberInfo,
  InfoWithIcon,
  MainList,
} from '../style';

type Props = {
  loading: boolean;
  sendFirstConnectionEmail: (user: any) => void;
  theme: Theme;
  baseUsers: any; // TODO type base user
};

const AdminBaseUsersListTablet = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const { loading, sendFirstConnectionEmail, theme, baseUsers } =
    props;

  if (loading) return <Loader size="48px" />;

  return (
    <MainList>
      {baseUsers
        .sort((a: any, b: any) =>
          a.lastName.localeCompare(b.lastName),
        )
        .map((user: any) => (
          <MemberCard key={`${user.uid}-row`}>
            <AvatarWithText
              size={'big'}
              userUid={user.uid}
              fullUserName={`${user.firstName} ${user.lastName}`}
              style={{ justifyContent: 'flex-start' }}
            />
            <MemberInfo>
              <InfoWithIcon>
                <Icon
                  color={theme.text}
                  size={'24px'}
                  name={icons.MAIL_OUTLINE}
                />
                <Mail
                  color={theme.text}
                  href={`mailto:${user.email}`}
                  key={`${user.uid}-mail`}
                >
                  {user?.email ?? '-'}
                </Mail>
              </InfoWithIcon>
              <InfoWithIcon>
                <Icon
                  color={theme.text}
                  size={'24px'}
                  name={icons.CALL_OUTLINE}
                />
                <Info key={`${user.uid}-phoneNumber`}>
                  {(user &&
                    displayPhoneNumberClearly(user.phoneNumber)) ??
                    '-'}
                </Info>
              </InfoWithIcon>
              <InfoWithIcon>
                <Icon
                  color={theme.text}
                  size={'24px'}
                  name={icons.PHONE_OUTLINE}
                />
                <Info key={`${user.uid}-smartphoneNumber`}>
                  {(user &&
                    displayPhoneNumberClearly(
                      user.smartphoneNumber,
                    )) ??
                    '-'}
                </Info>
              </InfoWithIcon>
              <InfoWithIcon>
                <Icon
                  color={theme.text}
                  size={'24px'}
                  name={icons.HOUR_GLASS_OUTLINE}
                />
                <Info key={`${user.uid}-memberSince`}>
                  {(user && formatDate(user.memberSince, 'yyyy')) ??
                    '-'}
                </Info>
              </InfoWithIcon>
              <InfoWithIcon>
                <Icon
                  color={
                    user.isWelcomeEmailSent
                      ? theme.success
                      : theme.text
                  }
                  size={'24px'}
                  name={
                    user.isWelcomeEmailSent
                      ? icons.CHECKMARK_OUTLINE
                      : icons.CLOSE_OUTLINE
                  }
                />
                <Paragraph style={{ padding: 0, paddingRight: 8 }}>
                  {user.isWelcomeEmailSent
                    ? formatDate(
                        user.isWelcomeEmailSent,
                        'dd/MM/yyyy HH:mm',
                      )
                    : 'Non envoyé'}
                </Paragraph>
                <TextButton
                  color={
                    user.isWelcomeEmailSent
                      ? theme.danger
                      : theme.text
                  }
                  disabled={
                    user.role === ROLE.ADMIN ||
                    user.role === ROLE.OFFICE
                  }
                  onClick={() => sendFirstConnectionEmail(user)}
                  title={
                    user.isWelcomeEmailSent
                      ? t('ADMIN.RESEND')
                      : t('ADMIN.SEND')
                  } // TODO translate
                />
              </InfoWithIcon>
            </MemberInfo>
          </MemberCard>
        ))}
    </MainList>
  );
};

export default withTheme(AdminBaseUsersListTablet);
