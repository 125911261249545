import React, { ReactElement } from 'react';
import { useMobileOrientation } from 'react-device-detect';
import { withTheme } from 'styled-components';

import { Theme } from '../../../theme';
import Icon from '../icon/Icon';
import CustomSubTitle from '../texts/CustomSubTitle';
import CustomTitle from '../texts/CustomTitle';

import { Container, Main } from './style';

type Props = {
  disabled?: boolean;
  iconColor: string;
  iconName: string;
  onClick: () => void;
  subTitle?: string;
  style?: React.CSSProperties;
  theme: Theme;
  title: string;
};

const MenuButton = (props: Props): ReactElement => {
  const {
    disabled = false,
    iconColor,
    iconName,
    onClick,
    subTitle,
    style,
    title,
    theme,
  } = props;
  const { isPortrait } = useMobileOrientation();

  return (
    <Main
      color={iconColor}
      disabled={disabled}
      isPortrait={isPortrait}
      onClick={onClick}
      style={style}
    >
      <Container color={theme.text}>
        <CustomTitle color={props.theme.text}>{title}</CustomTitle>
        {subTitle && (
          <CustomSubTitle
            color={props.theme.text}
            style={{ paddingTop: 8 }}
          >
            {subTitle}
          </CustomSubTitle>
        )}
        <Icon
          color={iconColor}
          size={'75%'}
          name={iconName}
          style={{
            alignItems: 'center',
            paddingTop: 24,
            justifyContent: 'center',
          }} // TO_UNDERSTAND => alignItems
        />
      </Container>
    </Main>
  );
};

export default withTheme(MenuButton);
