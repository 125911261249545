import React from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { isMobile } from 'react-device-detect';
import YoutubePlayer from './youtubePlayer';
import L from 'leaflet';

import Article from './Article';
import ImagesWrapper from '../../components/imageWrapper/ImagesWrapper';
import { openLinkNewTab } from '../../utils/openLinks';
import TextIconButton from '../../components/textIconButton/TextIconButton';
import CustomPageTitle from '../../components/texts/CustomPageTitle';
import { Theme } from '../../../theme';
import { icons } from '../../constants/icons';
import Paragraph from '../../components/paragraph/Paragraph';
import InConstruction from '../../components/inConstruction/InConstruction';
import { LINKS } from '../../constants/links';

import { Main, MapSection } from './style';
import 'leaflet/dist/leaflet.css';

import reseauFixe1 from './../../../assets/images/reseauFixe1.jpg';
import reseauFixe2 from './../../../assets/images/reseauFixe2.jpg';
import reseauFixe3 from './../../../assets/images/reseauFixe3.jpg';
import reseauFixe4 from './../../../assets/images/reseauFixe4.jpg';
import reseauFixe5 from './../../../assets/images/reseauFixe5.jpg';
import reseauFixe6 from './../../../assets/images/reseauFixe6.jpg';
import reseauFixe7 from './../../../assets/images/reseauFixe7.jpg';
import reseauFixe8 from './../../../assets/images/reseauFixe8.jpg';
import reseauFixe9 from './../../../assets/images/reseauFixe9.png';

import reseauExpo1 from './../../../assets/images/reseauExpo1.jpg';
import reseauExpo2 from './../../../assets/images/reseauExpo2.jpg';

import reseauHOJeune1 from './../../../assets/images/reseauHOJeune1.png';
import reseauHOJeune2 from './../../../assets/images/reseauHOJeune2.jpg';
import reseauHOJeune3 from './../../../assets/images/reseauHOJeune3.jpg';
import reseauHOJeune4 from './../../../assets/images/reseauHOJeune4.jpg';
import reseauHOJeune5 from './../../../assets/images/reseauHOJeune5.jpg';
import reseauHOJeune6 from './../../../assets/images/reseauHOJeune6.jpg';
import reseauHOJeune7 from './../../../assets/images/reseauHOJeune7.jpg';
import reseauHOJeune8 from './../../../assets/images/reseauHOJeune8.jpg';

import reseauN1 from './../../../assets/images/reseauN1.jpg';
import reseauN2 from './../../../assets/images/reseauN2.jpg';
import reseauN3 from './../../../assets/images/reseauN3.jpg';

import reseauNJunior1 from './../../../assets/images/reseauNJunior1.jpg';
import reseauNJunior2 from './../../../assets/images/reseauNJunior2.jpg';

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const RCM_COORDINATES: [number, number] = [
  43.95602483377092, 4.930113849213359,
];

type Props = { theme: Theme };

const MainVisitor = (props: Props) => {
  const { t } = useTranslation();
  const { theme } = props;

  return (
    <Main>
      <CustomPageTitle
        color={theme.text}
        style={{ paddingBottom: 40, fontSize: 52 }}
      >
        {t('COMMON.RCM_LONG')}
      </CustomPageTitle>

      <Article title={t('VISITOR.PARAGRAPH_1_TITLE')}>
        <Paragraph>
          {t('VISITOR.PARAGRAPH_1_DESCRIPTION_1')}
        </Paragraph>
        <Paragraph>
          {t('VISITOR.PARAGRAPH_1_DESCRIPTION_2')}
        </Paragraph>
      </Article>
      <Article title={t('VISITOR.PARAGRAPH_2_TITLE')}>
        <Paragraph>{t('VISITOR.PARAGRAPH_2_DESCRIPTION')}</Paragraph>
        <ImagesWrapper
          imgs={[
            {
              path: reseauFixe3,
            },
            { path: reseauFixe2 },
            { path: reseauFixe8 },
            { path: reseauFixe6 },
          ]}
        />
        <Paragraph>
          {t('VISITOR.PARAGRAPH_2_DESCRIPTION_2')}
        </Paragraph>
        <ImagesWrapper
          imgs={[
            { path: reseauFixe9 },
            { path: reseauFixe7 },
            { path: reseauFixe1 },
            { path: reseauFixe5 },
            { path: reseauFixe4 },
          ]}
        />
        <YoutubePlayer
          source={{
            link: LINKS.YOUTUBE_AIGUILLAGE,
            text: t('VISITOR.YOUTUBE_SOURCE'),
          }}
          title={t('VISITOR.YOUTUBE_1_TITLE')}
          videoId="uoWiPW9WOog"
        />
      </Article>
      <Article title={t('VISITOR.PARAGRAPH_3_TITLE')}>
        <Paragraph>{t('VISITOR.PARAGRAPH_3_DESCRIPTION')}</Paragraph>
        <ImagesWrapper
          imgs={[{ path: reseauExpo1 }, { path: reseauExpo2 }]}
        />
        <Paragraph>
          {t('VISITOR.PARAGRAPH_3_DESCRIPTION_2')}
        </Paragraph>
        <YoutubePlayer
          source={{
            link: LINKS.YOUTUBE_AIGUILLAGE,
            text: t('VISITOR.YOUTUBE_SOURCE'),
          }}
          title={t('VISITOR.YOUTUBE_2_TITLE')}
          videoId="rbAfY6Nf76A"
        />
      </Article>
      <Article title={t('VISITOR.PARAGRAPH_4_TITLE')}>
        <Paragraph>{t('VISITOR.PARAGRAPH_4_DESCRIPTION')}</Paragraph>
        <ImagesWrapper
          imgs={[
            {
              path: reseauN1,
            },
            { path: reseauN2 },
            { path: reseauN3 },
          ]}
        />
      </Article>
      <Article title={t('VISITOR.PARAGRAPH_5_TITLE')}>
        <Paragraph>{t('VISITOR.PARAGRAPH_5_DESCRIPTION')}</Paragraph>
        <ImagesWrapper
          imgs={[
            { path: reseauHOJeune1 },
            {
              path: reseauHOJeune2,
              legend: 'Gare de Saint Saturnin les Avignon',
            },
          ]}
        />
        <Paragraph>
          {t('VISITOR.PARAGRAPH_5_DESCRIPTION_2')}
        </Paragraph>
        <ImagesWrapper
          imgs={[{ path: reseauHOJeune3, legend: 'Plan de voie' }]}
        />
        <Paragraph>
          {t('VISITOR.PARAGRAPH_5_DESCRIPTION_3')}
        </Paragraph>
        <ImagesWrapper
          imgs={[
            { path: reseauHOJeune6 },
            { path: reseauHOJeune4 },
            { path: reseauHOJeune5 },
            { path: reseauHOJeune7 },
            { path: reseauHOJeune8 },
          ]}
        />
      </Article>
      <Article title={t('VISITOR.PARAGRAPH_6_TITLE')}>
        {/* <Paragraph>{t('VISITOR.PARAGRAPH_6_DESCRIPTION')}</Paragraph>
        <ImagesWrapper
          imgs={[{ path: reseauNJunior1 }, { path: reseauNJunior2 }]}
        /> */}
        <InConstruction style={{ paddingTop: 24 }} />
      </Article>
      <Article title={t('VISITOR.PARAGRAPH_7_TITLE')}>
        <Paragraph>
          {t('VISITOR.PARAGRAPH_7_DESCRIPTION_INFO')}
        </Paragraph>
        <Paragraph
          style={{
            padding: '0 0 40px 0',
            textDecorationLine: 'underline',
          }}
        >
          {t('VISITOR.PARAGRAPH_7_DESCRIPTION_HOURS')}
        </Paragraph>
        <TextIconButton
          color={theme.text}
          iconName={icons.CAR_OUTLINE}
          onClick={(e: any) => {
            openLinkNewTab(e, LINKS.MAPS);
          }}
          title={t('VISITOR.ITINERARY')}
        />
        <Paragraph style={{ padding: '32px 0 0 0' }}>
          {t('VISITOR.PARAGRAPH_7_DESCRIPTION_ADRESS')}
        </Paragraph>
        <MapSection>
          <MapContainer
            center={RCM_COORDINATES}
            zoom={18}
            scrollWheelZoom={true}
            style={{
              height: '504px',
              width: isMobile ? '100%' : '60%',
            }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker
              position={RCM_COORDINATES}
              interactive={false}
            ></Marker>
          </MapContainer>
        </MapSection>
      </Article>
    </Main>
  );
};

export default withTheme(MainVisitor);
