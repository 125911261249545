import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ToastLevels, displayToast } from '../../utils/Toast';
import { TOAST_LEVELS } from '../../constants/toastLevels';
import {
  deleteUserAvatar,
  getUserAvatar,
  getUserFullName,
  uploadUserAvatar,
} from '../../firebase/user';
import { icons } from '../../constants/icons';
import { Theme } from '../../../theme';
import TextIconLabel from '../textIconLabel/TextIconLabel';
import Loader from '../loader/Loader';
import IconButton from '../iconButton/IconButton';
import Alert from '../alert/Alert';

import {
  AvatarPlaceholder,
  AvatarPlaceholderText,
  ButtonsContainer,
  Main,
} from './style';
import {
  getFullNameInitials,
  stringToHslColor,
} from '../../utils/avatar';
import { handleFirebaseErrors } from '../../utils/errors';

type Props = {
  changeAvatarButon: {
    disabled: boolean;
    text: string;
  };
  theme: Theme;
  userUid: string;
  style?: React.CSSProperties;
};

const SIZE = {
  big: { img: 152, text: 72 },
  medium: { img: 64, text: 32 },
  small: { img: 24, text: 8 },
};

const AvatarModification = (props: Props): ReactElement => {
  const { changeAvatarButon, theme, style, userUid } = props;
  const [avatarPreview, setAvatarPreview] = useState('');
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState('');
  const [isDeleteAvatarModalOpen, setIsDeleteAvatarModalOpen] =
    useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);

        const u = await getUserFullName(userUid);
        setUserName(u);

        const avatar = await getUserAvatar(userUid);
        setAvatarPreview(avatar || '');
      } catch (error: any) {
        handleFirebaseErrors(error.code || error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const previewAvatar = (file: Blob) => {
    let fileReader: FileReader;
    fileReader = new FileReader();
    fileReader.onload = () => {
      const result = fileReader.result;
      if (result) {
        setAvatarPreview(result as string);
      }
    };
    fileReader.readAsDataURL(file);
  };

  const handleChangeImage = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files![0];

    try {
      uploadUserAvatar(
        file,
        userUid,
        t('AVATAR.UPLOAD_SUCCESS_TOAST'),
      );
      previewAvatar(file);
    } catch (error: any) {
      handleFirebaseErrors(error.code || error.message);
    }
  };

  const deleteAvatar = async () => {
    try {
      await deleteUserAvatar(userUid);
      setAvatarPreview('');
      displayToast(
        TOAST_LEVELS.SUCCESS as ToastLevels,
        t('AVATAR.DELETE_SUCCESS_TOAST'),
      );
    } catch (error: any) {
      handleFirebaseErrors(error.code || error.message);
    } finally {
      onUnsuscribeModalClose();
    }
  };

  const onUnsuscribeModalOpen = () => {
    setIsDeleteAvatarModalOpen(true);
  };

  const onUnsuscribeModalClose = () => {
    setIsDeleteAvatarModalOpen(false);
  };

  const imageContainer = () => {
    if (loading) {
      return (
        <div
          style={{
            height: SIZE.big.img,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Loader size={`${SIZE.big.img}px`} />
        </div>
      );
    }

    if (avatarPreview) {
      return (
        <img
          src={avatarPreview}
          alt="preview"
          style={{
            height: SIZE.big.img,
            width: SIZE.big.img,
            borderRadius: '50%',
            objectFit: 'cover',
          }}
        />
      );
    }

    return (
      <AvatarPlaceholder
        color={stringToHslColor(userName)}
        style={{
          height: SIZE.big.img,
          width: SIZE.big.img,
        }}
      >
        <AvatarPlaceholderText
          style={{
            fontSize: SIZE.big.text,
          }}
        >
          {getFullNameInitials(userName)}
        </AvatarPlaceholderText>
      </AvatarPlaceholder>
    );
  };

  return (
    <Main id="AvatarMain" style={style}>
      {imageContainer()}
      <ButtonsContainer>
        <IconButton
          color={theme.danger}
          disabled={!avatarPreview}
          iconName={icons.TRASH_OUTLINE}
          isbordered={true}
          onClick={onUnsuscribeModalOpen}
        />
        <TextIconLabel
          color={theme.text}
          disabled={loading || changeAvatarButon.disabled}
          iconName={icons.CREATE_OUTLINE}
          forInputLabel="uploadAvatar"
          title={changeAvatarButon.text}
        />
        <input
          accept="image/*"
          disabled={loading || changeAvatarButon.disabled}
          id="uploadAvatar"
          onChange={handleChangeImage}
          onClick={(event: any) => {
            event.target.value = null;
          }}
          type="file"
          style={{ display: 'none' }}
        />
      </ButtonsContainer>
      <Alert
        button={{
          color: theme.success,
          iconName: icons.CHECKMARK_OUTLINE,
          text: t('COMMON.CONFIRM'),
          action: deleteAvatar,
        }}
        closeAction={() => onUnsuscribeModalClose()}
        ismodalOpen={isDeleteAvatarModalOpen}
        message={t('AVATAR.CONFIRMATION_MODAL_MESSAGE')}
        secondaryButton={{
          color: theme.danger,
          iconName: icons.CLOSE_OUTLINE,
          text: t('COMMON.CANCEL'),
          action: () => onUnsuscribeModalClose(),
        }}
        title={t('AVATAR.CONFIRMATION_MODAL_TITLE')}
      />
    </Main>
  );
};

export default withTheme(AvatarModification);
