import { useState, useEffect } from 'react';

type Value = boolean | number | string | null;

export const getStorageValue = (key: string, defaultValue: Value) => {
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved!);

  return initial || defaultValue;
};

export const setStorageValue = (key: string, value: Value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const useLocalStorage = (key: string, defaultValue: Value) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    setStorageValue(key, value);
  }, [key, value]);

  return [value, setValue];
};

export const getVolatileStorageValue = (
  key: string,
  defaultValue: Value,
) => {
  const saved = sessionStorage.getItem(key);
  const initial = JSON.parse(saved!);

  return initial || defaultValue;
};

export const setVolatileStorageValue = (
  key: string,
  value: Value,
) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const useVolatileStorage = (
  key: string,
  defaultValue: Value,
) => {
  const [volatileValue, setVolatileValue] = useState(() => {
    return getVolatileStorageValue(key, defaultValue);
  });

  useEffect(() => {
    setVolatileStorageValue(key, volatileValue);
  }, [key, volatileValue]);

  return [volatileValue, setVolatileValue];
};
