import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import { Paragraph } from '../paragraph/style';

export const Main = styled.div`
  margin: 24;
`;

export const AlertText = styled(Paragraph).attrs(() => ({
  style: { padding: '24px 0 0 0', textAlign: 'center' },
}))``;

export const Footer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: ${!isMobile ? 'row' : 'column'};
  padding-top: 32px;
`;
