import React from 'react';

import Icon from '../icon/Icon';
import Loader from '../loader/Loader';

import { Badge, Container, Main } from './style';
import { withTheme } from 'styled-components';
import { Theme } from '../../../theme';
import { numberToShortString } from '../../utils/textDecoration';

type Props = {
  color: string;
  isbordered?: boolean;
  disabled?: boolean;
  iconName: string;
  loading?: boolean;
  onClick: (e?: any) => void;
  size?: string;
  style?: React.CSSProperties;
  badge?: number;
  theme: Theme;
};

const IconButton = (props: Props): JSX.Element => {
  const {
    color,
    isbordered = false,
    disabled = false,
    iconName,
    loading,
    onClick,
    size = '24px',
    style,
    badge,
    theme,
  } = props;

  return (
    <Main style={style} id="iconButton">
      {loading ? (
        <Loader size={size} />
      ) : (
        <Container
          color={color || theme.text}
          disabled={disabled}
          id="container"
          isBordered={isbordered}
          onClick={onClick}
        >
          {badge ? <Badge>{numberToShortString(badge)}</Badge> : null}
          <Icon name={iconName} size={size} color={color}></Icon>
        </Container>
      )}
    </Main>
  );
};

export default withTheme(IconButton);
