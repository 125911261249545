import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';

import { Theme } from '../../../theme';
import Avatar from '../avatar/Avatar';
import CustomPageTitle from '../texts/CustomPageTitle';
import { capitalizeFirstLetterForEachWord } from '../../utils/textDecoration';
import CustomTitle from '../texts/CustomTitle';

import { Main } from './style';

type Props = {
  fullUserName: string;
  theme: Theme;
  size: 'medium' | 'big';
  userUid: string;
  style?: React.CSSProperties;
};

const AvatarWithText = (props: Props): ReactElement => {
  const { fullUserName, size, style, theme, userUid } = props;

  const getTextBySize = () => {
    switch (size) {
      case 'big':
        return (
          <CustomPageTitle
            color={theme.text}
            style={{ textAlign: 'start' }}
          >
            {capitalizeFirstLetterForEachWord(fullUserName)}
          </CustomPageTitle>
        );
      case 'medium':
        return (
          <CustomTitle
            color={theme.text}
            style={{ textAlign: 'start' }}
          >
            {capitalizeFirstLetterForEachWord(fullUserName)}
          </CustomTitle>
        );
    }
  };

  return (
    <Main id="AvatarWithTextMain" style={style}>
      <Avatar
        fullUserName={fullUserName}
        size={size}
        userUid={userUid}
        style={{ paddingRight: 16 }}
      />
      {getTextBySize()}
    </Main>
  );
};

export default withTheme(AvatarWithText);
