import React, { ReactElement } from 'react';

import IconButton from '../iconButton/IconButton';

import { Main, TagText } from './style';
import Avatar from '../avatar/Avatar';
import { capitalizeFirstLetterForEachWord } from '../../utils/textDecoration';

type Props = {
  color: string;
  text: string;
  userUid: string;
  disabled?: boolean;
  id?: string;
  onClick?: (e: any) => void;
  rightIconName?: string;
  style?: React.CSSProperties;
};

const Tag = (props: Props): ReactElement => {
  const {
    userUid,
    disabled = false,
    color,
    id,
    onClick,
    rightIconName,
    style,
    text,
  } = props;

  return (
    <Main color={color} style={style}>
      <Avatar size={'small'} userUid={userUid} fullUserName={text} />
      <TagText color={color}>
        {capitalizeFirstLetterForEachWord(text)}
      </TagText>
      {onClick && rightIconName && id && (
        <IconButton
          disabled={disabled}
          color={color}
          onClick={() => onClick(id)}
          iconName={rightIconName}
          style={{ paddingLeft: 8 }}
        />
      )}
    </Main>
  );
};

export default Tag;
