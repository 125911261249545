import emailjs from '@emailjs/browser';
import { emailJSConfig } from '../../emailJSConfig';

type UserInfo = {
  email: string;
  name: string;
};

export enum EmailTemplate {
  WelcomeEmail = 'lrp6y0o',
}

export const sendEmail = async (
  to: UserInfo,
  template: EmailTemplate,
  options: any,
): Promise<boolean> => {
  const serviceId = emailJSConfig.serviceId;
  const templateId = template;
  try {
    await emailjs.send(serviceId, templateId, {
      name: to.name,
      recipient: to.email,
      ...options,
    });
    return true;
  } catch (error: any) {
    throw new Error(error);
  }
};
