import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Theme } from '../../../theme';
import CustomPageTitle from '../../components/texts/CustomPageTitle';
import Icon from '../../components/icon/Icon';
import Paragraph from '../../components/paragraph/Paragraph';
import { capitalizeFirstLetterForEachWord } from '../../utils/textDecoration';
import CustomTitle from '../../components/texts/CustomTitle';
import { icons } from '../../constants/icons';
import { formatDate } from '../../utils/date';
import i18n, { Language } from '../../../i18n';
import FR from '../../../i18n/fr.json';
import EN from '../../../i18n/en.json';
import { BREAKING_CHANGE, NEXTBIG } from '../../constants/version';

import { Grid, GridsContainer, Legend, Legends, Line } from './style';

type Props = {
  theme: Theme;
};

type Version = {
  DATE: string;
  DESCRIPTION: string;
  FEATURES?: string[];
};

const Versions = ({ theme }: Props): ReactElement => {
  const { t } = useTranslation();

  const getColor = (versionToCompare: string) => {
    if (!versionToCompare || versionToCompare === '0.0.0')
      return theme.text;
    const v1Parts = process.env
      .REACT_APP_VERSION!.split('.')
      .map((part) => parseInt(part));
    const v2Parts = versionToCompare
      .split('.')
      .map((part) => parseInt(part));
    const indexOfDifference = v1Parts.findIndex(
      (part, index) => part !== v2Parts[index],
    );
    if (indexOfDifference === -1)
      return v1Parts.length > v2Parts.length
        ? theme.success
        : theme.info2;
    return v1Parts[indexOfDifference] > v2Parts[indexOfDifference]
      ? theme.success
      : theme.info;
  };

  const empty = () => '-';

  const isMerged = (isMerged: boolean) =>
    isMerged ? icons.GIT_MERGE_OUTLINE : icons.GIT_BRANCH_OUTLINE;

  const getTranslationFile = () =>
    i18n.language === Language.FR ? FR : EN;

  const getVersion = (version: string) => {
    if (version.includes(NEXTBIG)) return t('VERSIONS.UNDETERMINED');
    else if (version === '0.0.0') return empty();
    return version;
  };

  return (
    <>
      <CustomPageTitle
        color={theme.text}
        style={{ paddingBottom: 40 }}
      >
        {t('VERSIONS.ACTUAL_VERSION_X', {
          version: process.env.REACT_APP_VERSION,
        })}
      </CustomPageTitle>
      <CustomTitle color={theme.text} style={{ paddingBottom: 16 }}>
        {t('VERSIONS.HISTORY')}
      </CustomTitle>
      <Legends>
        <Legend color={theme.success}>
          {t('VERSIONS.PUBLISHED_VERSION')}
        </Legend>
        <Legend color={theme.info2}>
          {t('VERSIONS.ACTUAL_VERSION')}
        </Legend>
        <Legend color={theme.info}>
          {t('VERSIONS.FUTURE_VERSION')}
        </Legend>
      </Legends>
      <GridsContainer>
        {Object.entries(getTranslationFile().VERSIONS.INFO)
          .reverse()
          .map(([key, value]) => {
            const v = value as Version;

            return (
              <Grid key={`${v.DESCRIPTION}`}>
                <Line color={getColor(key)} />
                <Icon
                  color={getColor(key)}
                  size={'48px'}
                  name={isMerged(getColor(key) !== theme.info)}
                />
                <Paragraph style={{ padding: 8 }}>
                  {getVersion(key) || empty()}
                </Paragraph>
                <Paragraph style={{ padding: 8 }}>
                  {capitalizeFirstLetterForEachWord(
                    v.DATE
                      ? formatDate(new Date(v.DATE), 'MMMM yyyy')
                      : empty(),
                  )}
                </Paragraph>
                <div>
                  <Paragraph style={{ padding: 8 }}>
                    {t(v.DESCRIPTION)}
                  </Paragraph>
                  {v.FEATURES && (
                    <ul>
                      {Object.values(v.FEATURES).map(
                        (feature: string, index: number) => {
                          return (
                            <li
                              style={{
                                color: Object.keys(v.FEATURES!)[
                                  index
                                ].includes(BREAKING_CHANGE)
                                  ? theme.danger
                                  : theme.text,
                              }}
                              key={feature}
                            >
                              {t(feature)}
                            </li>
                          );
                        },
                      )}
                    </ul>
                  )}
                </div>
              </Grid>
            );
          })}
      </GridsContainer>
    </>
  );
};

export default withTheme(Versions);
