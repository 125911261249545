import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import { BlogArticles, BlogArticle } from '../types/blog';
import { db, storage } from './connection';
import { handleFirebaseErrors } from '../utils/errors';
import { getTimestampFromStrDate } from '../utils/date';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { IMG_SIZE_LIMIT } from '../constants/imgSizeLimit';

export const createBlogArticle = async (
  info: Partial<BlogArticle>,
  isDraft: boolean,
) => {
  const uid = uuidv4();
  const docRef = doc(db, 'blogArticles', uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) throw new Error('already exist'); // TODO trad

  await setDoc(docRef, {
    author: info.author,
    isDeleted: false,
    isDraft: isDraft,
    simplifiedAuthor: info.simplifiedAuthor,
    postedDate: info.createdDate,
    createdDate: info.createdDate,
    uid: uid,
    message: info.message,
    title: info.title,
  });
};

export const updateBlogArticle = async (
  uuid: string,
  message: string,
  title: string,
  isDraft: boolean,
) => {
  try {
    const docRef = doc(db, 'blogArticles', uuid);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) throw new Error('not found'); // TODO trad

    await updateDoc(docRef, {
      isDraft: isDraft,
      message: message,
      title: title,
      postedDate: getTimestampFromStrDate(),
    });
  } catch (error: any) {
    handleFirebaseErrors(`${error.code}-update-article-blog`);
    throw new Error();
  }
};

export const deleteBlogArticle = async (uuid: string) => {
  try {
    const docRef = doc(db, 'blogArticles', uuid);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists) throw new Error('not found'); // TODO trad

    await updateDoc(docRef, {
      isDeleted: true,
    });
  } catch (error: any) {
    handleFirebaseErrors(`${error.code}-delete-article-blog`);
    throw new Error();
  }
};

export const getAllPostedBlogArticles =
  async (): Promise<BlogArticles> => {
    const querySnapshot = await getDocs(
      query(
        collection(db, 'blogArticles'),
        orderBy('createdDate', 'desc'),
        where('isDeleted', '==', false),
        where('isDraft', '==', false),
      ),
    );
    let infos: BlogArticles = [];

    // // TODO Query the first page of docs
    // const first = query(
    //   collection(db, 'cities'),
    //   orderBy('population'),
    //   limit(25),
    // );
    // const documentSnapshots = await getDocs(first);

    // // Get the last visible document
    // const lastVisible =
    //   documentSnapshots.docs[documentSnapshots.docs.length - 1];

    // // Construct a new query starting at this document,
    // // get the next 25 cities.
    // const next = query(
    //   collection(db, 'cities'),
    //   orderBy('population'),
    //   startAfter(lastVisible),
    //   limit(25),
    // );

    querySnapshot.forEach((doc: any) => {
      const data = doc.data();
      infos.push({
        author: data?.author,
        createdDate: data?.createdDate,
        isDeleted: false,
        isDraft: false,
        message: data?.message,
        postedDate: data?.postedDate,
        simplifiedAuthor: data?.simplifiedAuthor,
        title: data?.title,
        uid: data?.uid,
      });
    });

    return infos;
  };

export const get3FirstPostedBlogArticles = async (): Promise<
  BlogArticles | undefined
> => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(db, 'blogArticles'),
        orderBy('createdDate', 'desc'),
        where('isDeleted', '==', false),
        where('isDraft', '==', false),
        limit(3),
      ),
    );
    let infos: BlogArticles = [];

    querySnapshot.forEach((doc: any) => {
      const data = doc.data();
      infos.push({
        author: data?.author,
        createdDate: data?.createdDate,
        isDeleted: false,
        isDraft: false,
        message: data?.message,
        postedDate: data?.postedDate,
        simplifiedAuthor: data?.simplifiedAuthor,
        title: data?.title,
        uid: data?.uid,
      });
    });

    return infos;
  } catch (e) {}
};

export const getAllDraftedBlogArticles = async (
  authorUid: string,
): Promise<BlogArticles> => {
  const querySnapshot = await getDocs(
    query(
      collection(db, 'blogArticles'),
      orderBy('postedDate', 'desc'),
      where('isDeleted', '==', false),
      where('isDraft', '==', true),
      where('author', '==', authorUid),
    ),
  );
  let infos: BlogArticles = [];

  querySnapshot.forEach((doc: any) => {
    const data = doc.data();
    infos.push({
      author: data?.author,
      createdDate: data?.createdDate,
      isDeleted: false,
      isDraft: false,
      message: data?.message,
      postedDate: data?.postedDate,
      simplifiedAuthor: data?.simplifiedAuthor,
      title: data?.title,
      uid: data?.uid,
    });
  });

  return infos;
};

export const uploadBlogImage = async (file: Blob): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      if (file.size > IMG_SIZE_LIMIT.BLOG) {
        //TODO handle errors correctly
        handleFirebaseErrors('blog file oversize');
        reject({ success: 0 });
      }
      const storageRef = ref(storage, `blogImages/${uuidv4()}`); //TODO put article uid in path
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);

      resolve({
        success: 1,
        file: {
          url,
        },
      });
    } catch (error) {
      // TODO throw here
      reject({ success: 0 });
    }
  });
};
