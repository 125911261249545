import React, { ReactElement } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAuthState } from 'react-firebase-hooks/auth';

import { Theme } from '../../../theme';
import { goTo, routes } from '../../router/routes';
import { icons } from '../../constants/icons';
import IconButton from '../iconButton/IconButton';
import TextIconButton from '../textIconButton/TextIconButton';
import { auth } from '../../firebase/connection';
import CustomTitle from '../texts/CustomTitle';
import { ENV } from '../../constants/env';

import { Container, HeaderRight, Main } from './style';

type Props = {
  easterEgg: {
    toggler: () => void;
    isEasterEggActivate: boolean;
  };
  theme: Theme;
};

const Header = (props: Props): ReactElement => {
  const { t } = useTranslation();

  const { easterEgg, theme } = props;
  const [user] = useAuthState(auth);
  const history = useHistory();
  const location = useLocation();
  const goBack = () => history.goBack();
  const goToLogIn = () => goTo(location, history, routes.LOGIN);
  const goToMenu = () => goTo(location, history, routes.MENU.MAIN);
  const goToVisitor = () => goTo(location, history, routes.HOME);

  const isNotUserFinalization =
    location.pathname !== routes.USER_FINALIZATION;

  const headerLeftMobile = (): ReactElement => {
    return (
      <IconButton
        iconName={icons.ARROW_BACK_OUTLINE}
        onClick={goBack}
        color={theme.text}
      />
    );
  };
  const headerRightMobile = (): ReactElement => {
    return user ? (
      <IconButton
        iconName={icons.MENU_OUTLINE}
        onClick={goToMenu}
        color={theme.text}
        disabled={
          location.pathname === routes.USER_FINALIZATION ||
          location.pathname === routes.MENU.MAIN
        }
      />
    ) : (
      <IconButton
        iconName={icons.PERSON_CIRCLE_OUTLINE}
        onClick={goToLogIn}
        color={theme.text}
      />
    );
  };

  return isMobile ? (
    <Main>
      <Container>
        {headerLeftMobile()}
        <CustomTitle
          color={props.theme.text}
          onClick={() => goToVisitor()}
          style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
        >
          {t('COMMON.RCM_SHORT')}
        </CustomTitle>
        {headerRightMobile()}
      </Container>
    </Main>
  ) : (
    <Main>
      <Container>
        <CustomTitle
          color={props.theme.text}
          onClick={() =>
            isNotUserFinalization ? goToVisitor() : null
          }
          style={{
            whiteSpace: 'nowrap',
            cursor: isNotUserFinalization ? 'pointer' : 'default',
          }}
        >
          {t('COMMON.RCM_SHORT')}
        </CustomTitle>
        <HeaderRight>
          <TextIconButton
            color={
              easterEgg.isEasterEggActivate
                ? theme.text
                : theme.invertedText
            }
            iconName={icons.TRAIN_OUTLINE}
            onClick={easterEgg.toggler}
            title={t('COMMON.EASTER_EGG_BUTTON')}
          />
          {process.env.NODE_ENV === ENV.DEV &&
          user &&
          isNotUserFinalization ? (
            <IconButton
              badge={1}
              isbordered={true}
              color={theme.text}
              onClick={() => null}
              iconName={icons.NOTIFICATIONS_OUTLINE}
            />
          ) : null}
          {user ? (
            <TextIconButton
              color={theme.text}
              disabled={
                location.pathname === routes.USER_FINALIZATION ||
                location.pathname === routes.MENU.MAIN
              }
              iconName={icons.MENU_OUTLINE}
              onClick={goToMenu}
              title={t('COMMON.MENU')}
            />
          ) : (
            <TextIconButton
              color={theme.text}
              iconName={icons.PERSON_CIRCLE_OUTLINE}
              onClick={goToLogIn}
              title={t('COMMON.LOGIN')}
            />
          )}
        </HeaderRight>
      </Container>
    </Main>
  );
};

export default withTheme(Header);
