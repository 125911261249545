import { ReactElement, useState } from 'react';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAuthState } from 'react-firebase-hooks/auth';

import { Theme } from '../../../theme';
import { icons } from '../../constants/icons';
import EditionModal from '../../components/editionModal/EditionModal';
import Input from '../../components/input/Input';
import { validatePassword } from '../../utils/regexValidation';
import { auth, changePassword } from '../../firebase/connection';
import { displayToast, ToastLevels } from '../../utils/Toast';
import { TOAST_LEVELS } from '../../constants/toastLevels';
import { handleFirebaseErrors } from '../../utils/errors';
import { errorCodes } from '../../types/errors';

type Props = {
  closeChangePasswordModal: () => void;
  isChangePasswordModalOpen: boolean;
  theme: Theme;
};

const ChangePasswordModal = (props: Props): ReactElement => {
  const [user] = useAuthState(auth);
  const { t } = useTranslation();
  const {
    closeChangePasswordModal,
    isChangePasswordModalOpen,
    theme,
  } = props;

  const [loading, setLoading] = useState(false);
  const [isPasswordDisplayed, setIsPasswordDisplayed] =
    useState(false);
  const [isCurrentPasswordDisplayed, setIsCurrentPasswordDisplayed] =
    useState(false);
  const [
    isPasswordValidationDisplayed,
    setIsPasswordValidationDisplayed,
  ] = useState(false);

  const [inputFields, setInputFields] = useState({
    currentPassword: '',
    password: '',
    passwordValidation: '',
  });
  const [errors, setErrors] = useState({
    currentPassword: '',
    password: '',
    passwordValidation: '',
  });

  const handleChange = (e: any) => {
    setErrors({
      ...errors,
      [e.target.name]: '',
    });
    setInputFields({
      ...inputFields,
      [e.target.name]: e.target.value,
    });
  };

  const close = () => {
    setInputFields({
      currentPassword: '',
      password: '',
      passwordValidation: '',
    });
    setErrors({
      currentPassword: '',
      password: '',
      passwordValidation: '',
    });
    closeChangePasswordModal();
  };

  const validateValues = (inputValues: {
    currentPassword: string;
    password: string;
    passwordValidation: string;
  }) => {
    let err = {
      currentPassword: '',
      password: '',
      passwordValidation: '',
    };
    if (!inputValues.currentPassword) {
      handleFirebaseErrors(
        errorCodes.EmptyPassword,
        (x) => (err.currentPassword = x),
      );
    }
    if (!inputValues.passwordValidation) {
      handleFirebaseErrors(
        errorCodes.EmptyPassword,
        (x) => (err.passwordValidation = x),
      );
    }
    if (!inputValues.password) {
      handleFirebaseErrors(
        errorCodes.EmptyPassword,
        (x) => (err.password = x),
      );
    } else if (!validatePassword(inputValues.password)) {
      handleFirebaseErrors(
        errorCodes.InvalidPassword,
        (x) => (err.password = x),
      );
    } else if (
      inputValues.password !== inputValues.passwordValidation
    ) {
      handleFirebaseErrors(
        errorCodes.DifferentValidationPassword,
        (x) => (err.password = x),
      );
    } else if (inputValues.currentPassword === inputValues.password) {
      handleFirebaseErrors(
        errorCodes.SimilarPassword,
        (x) => (err.password = x),
      );
    }
    setErrors(err);

    return err;
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (
        Object.values(validateValues(inputFields)).every(
          (err) => err === null || err === '',
        )
      ) {
        await changePassword(
          user!,
          inputFields.currentPassword,
          inputFields.password,
        );
        setInputFields({
          currentPassword: '',
          password: '',
          passwordValidation: '',
        });
        close();
        displayToast(
          TOAST_LEVELS.SUCCESS as ToastLevels,
          t('SETTINGS.CHANGE_PASSWORD_SUCCESS'),
        );
      }
    } catch (error: any) {
      handleFirebaseErrors(error.code || error.message, (x) =>
        setErrors({ ...errors, currentPassword: x }),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <EditionModal
      button={{
        color: theme.success,
        iconName: icons.CHECKMARK_OUTLINE,
        text: t('COMMON.CONFIRM'),
        action: onSubmit,
      }}
      closeAction={close}
      ismodalOpen={isChangePasswordModalOpen}
      loading={loading}
      secondaryButton={{
        color: theme.danger,
        iconName: icons.CLOSE_OUTLINE,
        text: t('COMMON.CANCEL'),
        action: close,
      }}
      title={t('SETTINGS.CHANGE_PASSWORD')}
    >
      <Input
        color={theme.text}
        disabled={loading}
        error={errors.currentPassword}
        leftIconName={icons.LOCK_CLOSED_OUTLINE}
        onChange={handleChange}
        onRightIconClick={() =>
          setIsCurrentPasswordDisplayed(!isCurrentPasswordDisplayed)
        }
        placeholder={t('COMMON.PASSWORD')}
        required={true}
        rightIconName={
          isCurrentPasswordDisplayed
            ? icons.EYE_OUTLINE
            : icons.EYE_OFF_OUTLINE
        }
        title={t('SETTINGS.CURRENT_PASSWORD')}
        type={isCurrentPasswordDisplayed ? 'text' : 'password'}
        value={inputFields.currentPassword}
        style={{ marginTop: 32 }}
        name="currentPassword"
      />
      <Input
        color={theme.text}
        disabled={loading}
        error={errors.password}
        leftIconName={icons.LOCK_CLOSED_OUTLINE}
        onChange={handleChange}
        onRightIconClick={() =>
          setIsPasswordDisplayed(!isPasswordDisplayed)
        }
        placeholder={t('COMMON.PASSWORD')}
        title={t('SETTINGS.NEW_PASSWORD')}
        required={true}
        rightIconName={
          isPasswordDisplayed
            ? icons.EYE_OUTLINE
            : icons.EYE_OFF_OUTLINE
        }
        type={isPasswordDisplayed ? 'text' : 'password'}
        value={inputFields.password}
        name="password"
      />
      <Input
        color={theme.text}
        disabled={loading}
        error={errors.passwordValidation}
        leftIconName={icons.LOCK_CLOSED_OUTLINE}
        onChange={handleChange}
        onRightIconClick={() =>
          setIsPasswordValidationDisplayed(
            !isPasswordValidationDisplayed,
          )
        }
        placeholder={t('COMMON.PASSWORD')}
        title={t('SETTINGS.NEW_PASSWORD_CONFIRMATION')}
        required={true}
        rightIconName={
          isPasswordValidationDisplayed
            ? icons.EYE_OUTLINE
            : icons.EYE_OFF_OUTLINE
        }
        type={isPasswordValidationDisplayed ? 'text' : 'password'}
        value={inputFields.passwordValidation}
        name="passwordValidation"
      />
    </EditionModal>
  );
};

export default withTheme(ChangePasswordModal);
