import React, { ReactElement } from 'react';
import { withTheme } from 'styled-components';
import { Theme } from '../../../theme';

import { Circle, Main } from './style';
import Icon from '../icon/Icon';
import { icons } from '../../constants/icons';
import Loader from '../loader/Loader';

type Props = {
  checked: boolean;
  disabled?: boolean;
  loading?: boolean;
  onChange: () => void;
  style?: React.CSSProperties;
  theme: Theme;
};

const Switch = (props: Props): ReactElement => {
  const { checked, disabled, loading, onChange, style, theme } =
    props;

  const handleChange = () => {
    if (onChange && !disabled) {
      onChange();
    }
  };

  return (
    <Main
      onClick={handleChange}
      disabled={disabled}
      checked={checked}
      id="Switch"
      style={style}
    >
      <Circle>
        {loading ? (
          <Loader size={'16px'} />
        ) : (
          <Icon
            color={theme.text}
            size={'16px'}
            name={
              checked ? icons.CHECKMARK_OUTLINE : icons.CLOSE_OUTLINE
            }
          />
        )}
      </Circle>
    </Main>
  );
};

export default withTheme(Switch);
