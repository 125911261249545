import styled from 'styled-components';

import { device } from '../../constants/screenSizes';

export const Main = styled.div``;

export const Body = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 400px;

  @media ${device.laptop}, ${device.tablet} {
    max-width: 500px;
    width: 500px;
  }
`;

export const Footer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding-top: 32px;

  @media ${device.laptop}, ${device.tablet} {
    flex-direction: row;
  }
`;
