type BrandLogo = {
  insta: string;
  facebook: string;
  youtube: string;
};

type Editor = {
  background: string;
  text: string;
};

export type Theme = {
  background: string;
  brandLogo: BrandLogo;
  danger: string;
  classicButton: string;
  editor: Editor;
  info: string;
  info2: string;
  invertedBackground: string;
  invertedClassicButton: string;
  invertedText: string;
  off: string;
  success: string;
  text: string;
  warning: string;
};

export const common: Pick<
  Theme,
  'danger' | 'info' | 'info2' | 'off' | 'success' | 'warning'
> = {
  danger: '#E65239',
  info: '#50AAF0',
  info2: '#9A33CC',
  off: '#909090',
  success: '#24B533',
  warning: '#febb01',
};

const brandLogo: BrandLogo = {
  insta: '#C13584',
  facebook: '#4267B2',
  youtube: '#FF0000',
};

export const editor: Editor = {
  background: '#ffffff',
  text: '#282c34',
};

export const lightTheme: Theme = {
  ...common,
  brandLogo,
  background: '#ffffff',
  classicButton: '#F5F3EF',
  editor,
  invertedBackground: '#282c34',
  invertedClassicButton: '#40434B',
  invertedText: '#ffffff',
  text: '#282c34',
};
export const darkTheme: Theme = {
  ...common,
  brandLogo,
  background: '#282c34',
  classicButton: '#40434B',
  editor,
  invertedBackground: '#ffffff',
  invertedClassicButton: '#F5F3EF',
  invertedText: '#282c34',
  text: '#FAFAFA',
};
