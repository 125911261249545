import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  User,
  updateEmail,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

import { getDatabaseKeyForEnv } from '../utils/changeConfigWithEnv';
import { changeUserEmail } from './user';
import { errorCodes } from '../types/errors';

const app = initializeApp(getDatabaseKeyForEnv());
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export const logInWithEmailAndPassword = async (
  email: string,
  password: string,
) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (error: any) {
    throw new Error(error);
  }
};

export const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error: any) {
    throw new Error(error);
  }
};

export const logout = async () => {
  try {
    await signOut(auth);
  } catch (error: any) {
    throw new Error(error);
  }
};

export const changePassword = async (
  user: User,
  currentPassword: string,
  newPassword: string,
) => {
  if (!user) {
    throw new Error(errorCodes.InvalidUser);
  }
  try {
    if (auth.currentUser?.email) {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        currentPassword,
      );

      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
    }
  } catch (error: any) {
    throw new Error(error);
  }
};

export const changeEmail = async (
  user: User,
  newEmail: string,
  currentPassword: string,
) => {
  if (!user) {
    throw new Error(errorCodes.InvalidUser);
  }
  try {
    if (auth.currentUser?.email) {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        currentPassword,
      );

      await reauthenticateWithCredential(user, credential);
      await updateEmail(user, newEmail);
      await changeUserEmail(user.uid, newEmail);
    }
  } catch (error: any) {
    throw new Error(error);
  }
};
