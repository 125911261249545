import React, { ReactElement, useEffect, useState } from 'react';

import { getUserAvatar } from '../../firebase/user';
import Loader from '../loader/Loader';
import {
  getFullNameInitials,
  stringToHslColor,
} from '../../utils/avatar';
import { useVolatileStorage } from '../../utils/useLocalStorage';
import { ENV } from '../../constants/env';
import { handleFirebaseErrors } from '../../utils/errors';

import {
  AvatarPlaceholder,
  AvatarPlaceholderText,
  Main,
} from './style';

type Props = {
  fullUserName: string;
  size: 'small' | 'medium' | 'big';
  userUid: string;
  style?: React.CSSProperties;
};

const SIZE = {
  big: { img: 152, text: 56 },
  medium: { img: 64, text: 32 },
  small: { img: 26, text: 12 },
};

const Avatar = (props: Props): ReactElement => {
  const { fullUserName, size, style, userUid } = props;
  const [avatarPreview, setAvatarPreview] = useState('');
  const [loading, setLoading] = useState(true);
  const [isEasterEggAvatarActivated, setIsEasterEggAvatarActivated] =
    useVolatileStorage('isEasterEggAvatarActivated', false);

  useEffect(() => {
    const fetchUserAvatar = async () => {
      try {
        setLoading(true);

        const avatar = await getUserAvatar(userUid);
        setAvatarPreview(avatar || '');
      } catch (error: any) {
        handleFirebaseErrors(error.code || error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserAvatar();
  }, [userUid, isEasterEggAvatarActivated]);

  const getSize = () => {
    switch (size) {
      case 'big':
        return SIZE.big;
      case 'medium':
        return SIZE.medium;
      case 'small':
        return SIZE.small;
    }
  };

  const imageContainer = () => {
    if (loading) {
      return (
        <div
          style={{
            height: getSize().img,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Loader size={`${getSize().img}px`} />
        </div>
      );
    }

    if (avatarPreview) {
      return (
        <img
          src={avatarPreview}
          alt="preview"
          onClick={() =>
            process.env.NODE_ENV === ENV.DEV
              ? setIsEasterEggAvatarActivated(
                  !isEasterEggAvatarActivated,
                )
              : null
          }
          style={{
            height: getSize().img,
            width: getSize().img,
            borderRadius: '50%',
            objectFit: 'cover',
          }}
        />
      );
    }

    return (
      <AvatarPlaceholder
        color={stringToHslColor(fullUserName)}
        style={{
          height: getSize().img,
          width: getSize().img,
        }}
      >
        <AvatarPlaceholderText
          style={{
            fontSize: getSize().text,
          }}
        >
          {getFullNameInitials(fullUserName)}
        </AvatarPlaceholderText>
      </AvatarPlaceholder>
    );
  };

  return (
    <Main id="AvatarMain" style={style}>
      {imageContainer()}
    </Main>
  );
};

export default Avatar;
