import { t } from 'i18next';
import { Role } from '../types/users';

export const ROLE = {
  MEMBER: 'member',
  OFFICE: 'office',
  ADMIN: 'admin',
} as const;

export const memberRoleTranslation = (role: Role) => {
  switch (role) {
    case ROLE.MEMBER:
      return t('COMMON.ROLE.MEMBER');
    case ROLE.OFFICE:
      return t('COMMON.ROLE.OFFICE');
    case ROLE.ADMIN:
      return t('COMMON.ROLE.ADMIN');
    default:
      return t('COMMON.ROLE.MEMBER');
  }
};
