import React from 'react';

import { Main } from './style';

type Props = {
  children: React.ReactNode;
};

function Core(props: Props) {
  return <Main id="Core">{props.children}</Main>;
}

export default Core;
