import React from 'react';
import { Title as T } from './style';

type Props = {
  children: string;
  color: string;
  style?: React.CSSProperties;
};

const CustomTitle = (
  props: Props & React.HTMLAttributes<HTMLHeadingElement>,
): JSX.Element => {
  const { children, color, style, ...propsRest } = props;

  return (
    <T color={color} style={style} {...propsRest}>
      {children}
    </T>
  );
};

export default CustomTitle;
