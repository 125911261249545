import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import CustomPageTitle from '../../components/texts/CustomPageTitle';
import { Theme } from '../../../theme';
import CustomSubTitle from '../../components/texts/CustomSubTitle';
import Icon from '../../components/icon/Icon';
import { icons } from '../../constants/icons';

import { Main } from './style';

type Props = { theme: Theme };

const Maintenance = (props: Props): ReactElement => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Main id={location.pathname}>
      <Icon
        color={props.theme.danger}
        name={icons.WARNING_OUTLINE}
        size="100px"
        style={{ paddingBottom: 24 }}
      />
      <CustomPageTitle
        color={props.theme.text}
        style={{ paddingBottom: 40 }}
      >
        {t('MAINTENANCE.TITLE')}
      </CustomPageTitle>
      <CustomSubTitle color={props.theme.text}>
        {t('MAINTENANCE.SUBTITLE')}
      </CustomSubTitle>
    </Main>
  );
};

export default withTheme(Maintenance);
