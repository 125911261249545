export const generateYearsBetween = (
  startYear: number,
  endYear: number,
): string[] => {
  const endDate = endYear || new Date().getFullYear();
  let years = [];
  for (let i = startYear; i <= endDate; i++) {
    years.push(startYear.toString());
    startYear++;
  }
  return years;
};
